.section-about-history {
  margin-bottom: 50px;
  .section__title {
    z-index: 1;
  }
  .col-xl-6 {
    > * {
      @include up(1200) {
        padding-left: 20px;
      }
    }

    h2 {
      @include up(1200) {
        margin-top: 60px;
      }
    }
  }
}

.section-about-missions {
  // margin-bottom: 20px;
  .section__subtitle {
    @include up(1200) {
      margin-top: 18px;
      max-width: 54ch;
      margin-bottom: 20px;
    }
  }

  .row {
    .section__subtitle {
      margin-bottom: 40px;
    }
    @include up(1200) {
      margin-bottom: 0;
    }
    .col-xl-6 + .col-xl-6 {
      h4 {
        margin-bottom: 0.769em;
      }
      > * {
        @include up(1200) {
          padding-left: 20px;
        }
      }
    }
  }
}

.section-about-ethique {
  @include up(1200) {
    margin-bottom: 150px;
  }
  .section__title {
    z-index: 1;
    margin-bottom: 0;
  }
  h3 {
    max-width: 50ch;
    margin-bottom: 2.5em;
  }
}

.ethic-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 45px;
  }

  h4 {
    margin-bottom: 1.154em;
  }

  .btn-main {
    margin-top: auto;
    width: fit-content;
  }

  p {
    --about-p-fontsize: clamp(1rem, 0.934rem + 0.26vw, 1.125rem);
    font-size: var(--about-p-fontsize);
    line-height: 1.5;
  }

  @include down(1199) {
    margin-bottom: 20px;
  }

  @include up(1200) {
    height: 100%;
  }
}

.section-about-statistiques {
  .section__title {
    margin-bottom: 10px;
  }
  margin-top: 50px;
  .numbers {
    @include up(1200) {
      top: 50px;
      margin-right: auto;

      h4 {
        position: absolute;
        left: -55px;
        max-width: 16ch;
        top: -50px;
      }
    }

    &--campaigns {
      .number__suffix {
        color: var(--primary);
      }
      svg {
        stroke: black;
      }
    }

    &--community {
      .number img {
        @include up(1200) {
          width: 60px;
        }
      }
    }
  }

  .number {
    // padding: 12px 20px;
    width: 160px;
    height: 120px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;

    @include up(1200) {
      height: 177px;
    }

    &__suffix {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  .number--campaign-one {
    right: -20px;
    top: 30px;
  }
  .number--campaign-two {
    left: -14px;
    top: calc(50% - 45px);

    @include up(1200) {
      left: -80px;
    }
  }
  .number--campaign-three {
    left: calc(50% - 45px);
    bottom: 6px;
    @include up(1200) {
      bottom: -20px;
    }
  }
}

.stats-wrapper {
  margin-top: 60px;
  @include up(1200) {
    margin-top: 112px;
  }

  .row {
    margin-bottom: 120px;
  }
}

.stats {
  display: grid;
  gap: var(--bs-gutter-x);
  grid-template-columns: 1fr 1fr;
}

.stat-item {
  position: relative;
  box-shadow: 0px 10px 40px rgba(209, 220, 242, 0.8);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: var(--font-title);
  padding: 28px 0 28px 0;
  &--highlight {
    text-decoration: none;
    background: var(--primary);
    color: white;

   
    .stat-item__value {
      color: white;
    }

    &:hover{
      background:white;
      color:   var(--primary);
      .stat-item__value {
        color:   var(--primary);
      }
    }


    .infos-label {
      cursor: pointer;
      background: #eeb72d;
      opacity: 0.5;
      width: 18px;
      height: 18px;
      display: flex;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      transition: 250ms opacity;

      font-weight: 700;
      font-size: 12px;

      color: #ffffff;
      font-family: var(--font-title);

      &:hover {
        opacity: 1;
      }
    }
  }
  &__value {
    text-align: center;
    color: var(--primary);
    display: block;
    font-size: var(--font-size-h2);
    font-weight: 700;
  }
  &__legend {
    // max-width: 10ch;
    font-weight: 700;
    font-family: var(--font-title);
    font-size: 16px;
    line-height: 1.1;
  }
}

.section-about-risks {
  margin-bottom: 40px;
  @include up(1200) {
    margin-bottom: 120px;
  }
  .section__title {
    z-index: 1;
  }
  .col-xl-5 {
    h2 {
      position: relative;
      z-index: 2;
      @include up(1200) {
        margin-top: 28px;
      }
    }
  }
}

.section-about-team {
  .section__title {
    margin-bottom: 28px;
  }

  .noffset-gutter-half {
    @include up(1200) {
      max-width: 70ch;
    }
  }
}

.team {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  @include up(768) {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include up(1200) {
    margin-top: 90px;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;
  text-align: center;

  @include up(768) {
    width: calc(50% - var(--bs-gutter-x));
  }
  @include up(1200) {
    width: 27%;
    margin-bottom: 90px;
  }

  &:first-of-type {
    @include up(1200) {
      margin-left: auto;
      margin-right: 70px;
    }
  }
  &:nth-of-type(2) {
    @include up(1200) {
      margin-right: auto;
      margin-left: 70px;
    }
  }

  &__name,
  &__function {
    font-family: var(--font-title);
    font-weight: 700;
  }

  &__name {
    font-size: var(--font-size-h4);
  }
  &__function {
    font-size: 16px;
    line-height: 1.1;
  }

  &__image {
    position: relative;
  }

  &__image-artefact {
    position: absolute;
    z-index: 1;
    background-repeat: no-repeat;
  }

  .btn-main {
    margin-top: 15px;
  }
}

.modal {
  .team-member {
    width: 100% !important;
    margin-bottom: 30px !important;
  }
}

.artefact {
  &--circle {
    width: 22px;
    height: 21px;
    left: 26px;
    top: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 9 9'%3E%3Ccircle cx='4.5' cy='4.5' r='4' stroke='url(%23a)'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='9' y1='9' y2='9' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  &--circle-small {
    width: 9px;
    height: 9px;
    right: -35px;
    bottom: 26px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 9 9'%3E%3Ccircle cx='4.5' cy='4.5' r='4' stroke='url(%23a)'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='9' y1='9' y2='9' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  &--triangle {
    height: 26px;
    width: 25px;
    left: -11px;
    bottom: 9px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 22 19'%3E%3Cpath fill='url(%23a)' d='M20.614 1.908c.808.055 1.27.935.815 1.553L10.503 18.276c-.438.595-1.407.5-1.76-.173L.272 1.982C-.082 1.309.438.55 1.217.603l19.397 1.305Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='-7.282' x2='2.843' y1='1.678' y2='24.401' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  &--triangle-border {
    height: 30px;
    width: 30px;
    right: -25px;
    bottom: -22px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 29 25'%3E%3Cpath stroke='%23539F84' d='M27.49 23.642 1.425 21.338c-.393-.035-.59-.451-.356-.754L16.269.95c.234-.302.726-.267.89.064l10.865 21.939c.167.338-.133.725-.534.69Z'/%3E%3C/svg%3E%0A");
  }
}

.cta-join {
  color: white;
  position: relative;
  margin-bottom: 20px;
  @include down(576) {
    height: 280px;
  }
  @include up(1200) {
    margin-bottom: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    position: absolute;
    @include up(1200) {
      padding: 50px 80px 50px 60px;
    }
    @include up(1400) {
      padding: 67px 100px 70px 80px;
    }

    h2,
    h3 {
      color: white;
      margin-bottom: 17px;
      @include up(1200) {
        max-width: 15ch;
      }
    }

    p {
      margin-bottom: 8px;
      @include up(1400) {
        max-width: 40ch;
        margin-bottom: 40px;
      }
    }

    a,
    button {
      margin-top: auto;
    }
  }
}

.section-about-ctas {
  .container {
    @include down(576) {
      padding: 0;
    }
  }
  .panel {
    @include up(1200) {
      padding: 67px 100px 70px 80px;
    }
    h2 {
      margin-bottom: 9px;
    }

    h4 {
      margin-bottom: 32px;
    }
  }
}
