.glossary {
  @include up(1200) {
    /*gap: 10%;
    columns: 2;*/
  }
  &__element {
    //break-inside: avoid-page;
    flex: 0 1 45%;
    margin-bottom: 30px;

    @include up(1200) {
      margin-bottom: 100px;
    }
    h3 {
      margin-bottom: 20px;
    }
    p {
      line-height: 1.7;

      @include up(1200) {
        font-size: 20px;
      }
    }
  }
}

.col {
  .accordion {
    display: grid;
    gap: 30px;

    @include up(768) {
      grid-template-columns: 1fr 1fr;
    }
    &-item {
      height: fit-content;
    }
  }
}

.accordion {
  border-radius: 0;
  &-item {
    break-inside: avoid-column;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 10px 30px #eef1f7;
    border-radius: 10px !important;
    border: none;
    padding: 0;
    .accordion-body {
      padding: 0 20px 16px 20px;
      @include up(1200) {
        padding: 0 40px 32px 40px;
      }
    }
    .accordion-button {
      padding: 20px 20px;
      border-radius: 10px !important;
      font-family: var(--font-title);
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      gap: 16px;

      @include up(1200) {
        padding: 32px 40px;
        font-size: 20px;
      }
      &::after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE1IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTMuMzMzMyAwTDE0LjUxMTggMS4xMTk1OUw3LjI1NTkyIDguMDEyNzFMMCAxLjExOTU5TDEuMTc4NTEgMEw3LjI1NTkyIDUuNzczNTRMMTMuMzMzMyAwWiIgZmlsbD0iIzJCMzQ0NyIvPgo8L3N2Zz4K");
        width: 15px;
        height: 8px;
        background-size: auto;
        margin-left: auto;
      }
      &.accordion-button:not(.collapsed) {
        background: white;
        color: $black;
        box-shadow: none;
      }
      &:focus {
        border-color: transparent;
        outline: 0;
        box-shadow: none;
      }
    }
  }
}
