.video-wrapper {
  position: relative;
  video {
    width: 100%;
    height: auto;
  }
  svg {
    position: absolute;
    bottom: 30px;
    left: 30px;
    z-index: 1;
  }
}
