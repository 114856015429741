.progress {
  display: flex;
  height: 16px;
  background: var(--progress-bar-bg, linear-gradient(rgba(40, 157, 180, 0.3), rgba(83, 159, 132, 0.3)));
  border-radius: 54px;
  overflow: hidden;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 5px 12px;
  position: relative;

  &[aria-valuenow="0"] {
    --progress-bar-bg: rgba(238, 183, 45, 0.5);
  }
  &[aria-valuenow="100"] {
    --progress-bar-bg: rgba(255, 255, 255, 0.3);
    --progress-bar-color: white;
  }

  &--equity {
    padding: 9px 12px;
    height: 24px;

    &[aria-valuenow="100"] {
      .progress-inner::after {
        display: none;
      }
    }
  }
}

.progress-inner {
  position: relative;
  background-color: var(--progress-bar-foreground, white);
  width: 100%;
  height: 6px;
  box-sizing: border-box;
  display: flex;
  border-radius: 54px;
}

.progress-bar {
  border-radius: 54px;
  background: var(--progress-bar-color, linear-gradient(90deg, $yellow, #6db29b));

  height: 100%;
  transition: width 250ms;
}

.progress {
  .progress-inner::after {
    //content: "";
    content: none;
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    border: 3px solid #ededee;
    z-index: 2;
    top: -4px;
    right: 0;
    transform: translateX(6px);
  }
}
