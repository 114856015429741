.dashboard-general {
  .bg-icon {
    right: -60px;
    top: 700px;
    width: 550px;
    height: 550px;
    --bg-icon-rotate: -25deg;
    z-index: -1;
  }
}

.dashboard {
  margin-top: 68px;
  margin-bottom: 65px;
}

.dashboard-panel {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 10px 30px #eef1f7;
  margin-bottom: 30px;
  header {
    // background: #000;
    background: #ededee;
    text-align: center;
    padding: 12px 0;
    position: relative;

    @include up(1200) {
      padding: 17px 0;
    }
  }

  &__content {
    padding: 25px;
    text-align: center;
    .invalid &{
      background: rgba($yellow,0.6);
    }
  }

  &__value {
    display: block;
    font-size: var(--font-size-h3);
    font-family: var(--font-title);
    font-weight: 700;
    &.small{
      font-size: 26px;
    }
  }

  &__legend {
    font-size: 16px;
  }

  &--infos {
    .dashboard-panel__infos-label {
      cursor: pointer;
      background: #eeb72d;
      opacity: 0.5;
      width: 25px;
      height: 25px;
      display: flex;
      position: absolute;

      top: 50%;
      transform: translateY(-50%);

      right: 12px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      transition: 250ms opacity;

      font-weight: 700;
      font-size: 16px;

      color: #ffffff;
      font-family: var(--font-title);

      &:hover {
        opacity: 1;
      }
    }
  }
}

.dashboard-pie {
  background: #ffffff;

  border-width: 0px 0px 1px 1px;
  border-style: solid;
  border-color: #ededee;

  box-shadow: 0px 10px 30px #eef1f7;
  margin-bottom: 30px;
  padding: 20px 20px 20px 20px;

  @include up(1580) {
    padding: 56px 50px 60px 90px;
  }

  .d-flex {
    gap: 20px;
  }

  canvas {
    margin: 0 auto;
  }
}

.pie-legend__label {
  display: block;
  font-size: 16px;
  font-weight: 600;
}

.color-default {
  color: #616161;
}
.color-yellow {
  color: #eeb72d;
}
.color-green {
  color: #539f84;
}
.color-green-2,
.color-equity {
  color: #00ae65;
}

.dashboard-equity {
  .dashboard {
    margin-top: 30px;
  }
}

.chart-container {
  position: relative;
  height: 340px;
  // height: 100vh;
  // width: 80vw;
}
