.form-group {
  margin-bottom: 24px;
  .tooltipC {
    top: 46px;
    position: absolute;
    right: 11px;
  }
  .form-label {
    &.required{
      &::after{
        content: '*';
        display: inline-block;
        font-size: 10px;
        margin-left: 5px;
        top: -5px;
        position: relative;
      }
    }
    &.classic{
      font-size: 18px;
      color: $black;
      margin-bottom: 13px;
    }
    &.labelSwap {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.5;
      position: absolute;
      opacity: .7;
      top: 14px;
      left: 41px;
      @include down(1199) {
        left: 16px;
      }
      transition: all 250ms;
    }
  }
  .form-control{
    color: $black;
    font-size: 16px;
    line-height: 30px;
    border-radius: 3px;
    border: 1px solid #E8EDF8;
    &.labelSwap {
      &:focus,
      &:not(:placeholder-shown) {
        line-height: 43px;
        opacity: 1;
        color: var(--color-title);
        padding-bottom: 0;
        font-size: 16px;
        & ~ .labelSwap {
          font-weight: 600;
          font-size: 14px;
          top: 5px;
          color: $primary;
        }
      }
    }
  }
  .select-style{
    .form-control{

      option[value=""] {
          color: gray; /* Exemple de style : texte en gris */
          font-style: italic; /* Exemple : texte en italique */
      }

      cursor: pointer;
      &.labelSwap {
        line-height: 40px;
        opacity: 1;
        color: var(--color-title);
        padding-bottom: 0;
        padding-top: 16px;
      }
    }
    .form-label {
      &.labelSwap {
        position: absolute;
        font-weight: 600;
        font-size: 14px;
        top: 5px;
        left: 24px;
        color: $primary;
      }
    }
  }
}

.form-check,
.form-group {
  position: relative;
  &.has-error {
    input,
    select,
    textarea {
      border: 1px $red solid;
      outline: none;
    }
    .help-block {
      font-size: 12px;
      color: $red;
      position: absolute;
      padding: 0 5px;
      background: white;
      bottom: 50px;
      right: 5px;
      strong {
        font-weight: 400;
      }
    }
  }
}

.form-check{
  &-inline{
    display: inline-flex !important;
    margin-right: 2rem;
  }
  &.has-error{
    .form-check-label{
      color: $red;
    }
  }
}

#mc_embed_signup input.mce_inline_error {
  border: 1px $red solid !important;
}

%input {
  display: block;
  width: 100%;
  padding: 0.8125rem 1.5rem;
  @include down(1199) {
    padding: 0.5rem 1rem;
  }
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #539f84;
  background-color: white;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out;
  box-shadow: 0px 10px 30px #eef1f7;

  &::placeholder {
    color: var(--color-title);
  }
  // &:hover {
  //   color: #539f84;
  //   outline: 1px solid #e5fddc;
  // }
  &:focus {
    color: #539f84;
    outline: 1px solid #539f84;
    box-shadow: none;
  }
  &.mce_inline_error {
  }
}

.form-control {
  @extend %input;
}

textarea.form-control {
  height: auto;
  max-height: 220px;
  border-radius: 3px;
  resize: none;
}

input::placeholder {
  color: var(--color-title);
  .home & {
    color: #539f84;
    background: linear-gradient(90deg, #289db4, #539f84);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1;
  }
}

.form-check {
  display: flex;
  gap: 18px;
  align-items: flex-start;
  margin-bottom: 16px;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-form-control-bg);
  width: 21px;
  height: 21px;
  margin-top: 3px;

  @include up(1200) {
    width: 25px;
    height: 25px;
  }
  flex-shrink: 0;
  // margin-top: 0.25em;

  background-color: white;
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 0.25em;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  position: relative;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.1), 0px 10px 20px #d1dcf2;
  // top: 5px;
}

.form-check-label {
  font-size: 18px;
  line-height: 1.8em;
  font-weight: 400;
  color: $black;
  cursor: pointer;
  a {
    font-weight: 700;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

label[for="estate"] {
  display: none;
}

.form-check-input:checked {
  background-color: $yellow;
  border-color: $yellow;
}
.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-section {
  margin-bottom: 0px;
  h3 {
    margin-bottom: 1.111em;
  }
}

.form-select {
  @extend %input;
  padding-right: 3rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 22 13'%3E%3Cpath stroke='%232B3447' stroke-width='2' d='M21 1 11 11 1 1'/%3E%3C/svg%3E");
  background-size: 15px 11.818181px;
  background-repeat: no-repeat;
  background-position: calc(100% - 1.5rem) center;

  &:focus-within {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 22 13'%3E%3Cpath stroke='%23539f84' stroke-width='2' d='M21 1 11 11 1 1'/%3E%3C/svg%3E");
  }
}

.form-switcher {
  margin-left: auto;
  display: block;
  position: relative;
  overflow: hidden;
  width: fit-content;
  border-radius: 44px;
  .switcher-btn {
    display: inline-block;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;

    padding: 18px 33px 18px 47px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: var(--font-title);
    font-size: 16px;
    color: white;
  }

  .switcher-btn-right {
    padding-left: 35px;
  }

  input[type="radio"].switcher-toggle {
    display: none;
  }
  input[type="radio"].switcher-toggle + label {
    cursor: pointer;
  }

  input[type="radio"].switcher-toggle + label:before,
  input[type="radio"].switcher-toggle + label:after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -2;
  }
  input[type="radio"].switcher-toggle + label:before {
    background: #d9d9d9;
    // border-radius-top-right: 44px;
    border-top-left-radius: 44px;
    border-bottom-left-radius: 44px;
  }

  input[type="radio"].switcher-toggle-right + label:before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 44px;
    border-bottom-right-radius: 44px;
  }

  input[type="radio"].switcher-toggle + label:after {
    background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
    border-radius: 44px;

    transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
  }
  input[type="radio"].switcher-toggle.switcher-toggle-left + label {
    border-right: 0;
  }
  input[type="radio"].switcher-toggle.switcher-toggle-left + label:after {
    left: 100%;
  }
  input[type="radio"].switcher-toggle.switcher-toggle-right + label {
    margin-left: -5px;
  }
  input[type="radio"].switcher-toggle.switcher-toggle-right + label:after {
    left: -100%;
  }
  input[type="radio"].switcher-toggle:checked + label {
    cursor: default;
    color: #fff;
    transition: color 200ms;
  }
  input[type="radio"].switcher-toggle:checked + label:after {
    left: 0;
  }
}

.panel .form-control {
  box-shadow: none;
}

