.loan-questions {
  display: none;
  @include up(1200) {
    display: block;
  }
}

.question-card {
  padding-top: 70px;
  margin-top: 35px;
  box-shadow: 0px 10px 30px #eef1f7;
  border-radius: 10px;
  padding-bottom: 59px;
  background: white;
}

.question-grid {
  display: grid;
  grid-template-columns: 27.8% 34.1333% 27%;
  justify-content: space-between;

  h4 {
    margin-bottom: 11px;
    max-width: 15ch;
  }

  p {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 600;
  }

  div:first-of-type {
    p {
      font-weight: 600;
    }
  }
  div:last-of-type {
    position: relative;
    @include up(1200) {
      padding-left: 40px;
    }
    svg {
      position: absolute;
      right: 16px;
      top: 30px;
    }

    .btn-main {
      margin-top: 40px;
    }
  }
}

.resultTitle{
  border-left: 2px white solid;
  padding: 5px 0 5px 25px;
  font-size: 18px;
  font-family: var(--font-title);
  font-weight: 700;
}