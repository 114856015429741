.footer {
  background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
  padding: 40px 0;
  @include up(1200) {
    padding: 70px 45px;
    margin: 40px;
  }

  @include up(1580) {
    .container {
      max-width: 1610px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.footer .navbar-nav {
  @include up(1200) {
    gap: 0;
    align-items: flex-start;
  }
}
.footer-link {
  font-size: 12px;
  display: block;
  color: white;
  font-family: var(--font-general);
  text-decoration: none;
  line-height: 1.7;
  font-weight: 600;
  &:hover {
    color: white;
    text-decoration: underline;
  }
  @include up(1600) {
    font-size: 13px;
  }
}

.footer .col-xxl-10 {
  @include up(1780) {
    width: 1600px;
  }
}

.footer .nav-link,
.footer .dropdown-item {
  font-size: 12px;
  font-family: var(--font-general);
  padding: 0;

  @include up(1600) {
    font-size: 13px;
  }
}

.footer .dropdown-menu {
  padding: 0 0 0 10px;
  @include down(1199) {
    padding: 16px;
  }
}
.footer .dropdown-menu li + li {
  margin-top: 0;
}

.footer .nav-link,
.footer .dropdown-toggle {
  @include down(1199) {
    display: block;
    border-bottom: 1px solid white;
    text-transform: uppercase;
    padding: 16px 0;
  }
}

.footer .navbar-nav .dropdown-toggle::after {
  @include down(1199) {
    top: 18px;
  }
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  @include up(1200) {
    margin-top: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

// .footer .dropdown-menu {
//   background: transparent;
//   color: white;
// }

.footer-socials {
  display: flex;
  gap: 25px;
}
