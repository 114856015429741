/* -----------------------------------------------------
   Config
   ----------------------------------------------------- */
@import "base/variables";
@import "base/mixins";

@import "base/typography";

/* -----------------------------------------------------
   Libs
   ----------------------------------------------------- */
@import "vendor/swiper";

/* -----------------------------------------------------
   Layout
   ----------------------------------------------------- */
@import "layout/utils";
@import "layout/general";
@import "layout/header";
@import "layout/footer";

/* -----------------------------------------------------
   Components
   ----------------------------------------------------- */
@import "components/button";
@import "components/form";
@import "components/pager";
@import "components/dropdown";
@import "components/modal";
@import "components/video";
@import "components/table";
@import "components/progress";
@import "components/accordion";
@import "components/project";
@import "components/label";
@import "components/question";
@import "components/alert";
@import "components/calendar";
@import "components/pagination";

/* -----------------------------------------------------
   Pages
   ----------------------------------------------------- */
@import "pages/about";
@import "pages/home";
@import "pages/page";
@import "pages/contact";
@import "pages/borrow";
@import "pages/crowdlending";
@import "pages/stories";
@import "pages/apply";
@import "pages/reference";
@import "pages/jobs";
@import "pages/invest";
@import "pages/article";
@import "pages/partner";

/* -----------------------------------------------------
   Account
   ----------------------------------------------------- */
@import "pages/account/calendar";
@import "pages/account/documents";
@import "pages/account/investments";
@import "pages/account/dashboard";
@import "pages/account/profile";
