.section-submit {
  margin-bottom: 80px;
  .section__title {
    @include up(1200) {
      margin-bottom: 10px;
    }
  }

  p {
    margin-top: 20px;
    max-width: 68ch;
  }
}

.submit-steps {
  margin-top: 30px;
  display: grid;
  gap: 40px 28px;
  padding: 20px;
  margin-bottom: 80px;
  @include up(768) {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  }
}

.submit-step {
  position: relative;

  display: flex;
  align-items: center;
  gap: 30px;

  @include up(768) {
    flex-direction: column;
  }
  &__number {
    font-family: var(--font-title);
    font-weight: 700;
    font-size: var(--font-size-h3);
    line-height: 1.1;
    color: var(--primary);
    position: absolute;
    z-index: -1;
    top: -22px;
    left: calc(50% - 56px);
  }
  &__icon {
    flex-shrink: 0;
    background: #ffffff;
    box-shadow: 0px 10px 30px #eef1f7;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: grid;
    place-items: center;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: "";
      background-repeat: no-repeat;
    }
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 8'%3E%3Cpath fill='%23fff' stroke='url(%23a)' d='M7.597 3.809a3.304 3.304 0 0 1-3.299 3.309A3.304 3.304 0 0 1 1 3.808 3.304 3.304 0 0 1 4.298.5c1.82 0 3.299 1.48 3.299 3.309Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.5' x2='8.097' y1='7.618' y2='7.618' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    }
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 7 7'%3E%3Cpath fill='url(%23a)' d='M6.651 4.269a1 1 0 0 1-.62 1.616l-4.452.706A1.002 1.002 0 0 1 .49 5.243l1.616-4.22a.998.998 0 0 1 1.71-.268l2.836 3.514Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.8' x2='-2.516' y1='-2' y2='6.605' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }

  &__content {
    display: block;
    font-family: var(--font-title);
    font-weight: 700;
    line-height: 1.2;

    @include up(768) {
      text-align: center;
    }
  }

  &--1 {
    .submit-step__icon {
      &::before {
        width: 9px;
        height: 8px;
        top: -6px;
      }
      &::after {
        width: 9px;
        height: 9px;
        right: 5px;
        bottom: 20px;
      }
    }
  }
  &--2 {
    .submit-step__icon {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 7 7'%3E%3Cpath fill='url(%23a)' d='M6.651 4.269a1 1 0 0 1-.62 1.616l-4.452.706A1.002 1.002 0 0 1 .49 5.243l1.616-4.22a.998.998 0 0 1 1.71-.268l2.836 3.514Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.8' x2='-2.516' y1='-2' y2='6.605' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

        width: 13px;
        height: 13px;
        top: -6px;
      }
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 5 5'%3E%3Cpath fill='url(%23a)' stroke='url(%23b)' d='M4.34 2.176c0 .928-.748 1.677-1.67 1.677-.92 0-1.67-.75-1.67-1.677S1.75.5 2.67.5c.922 0 1.67.75 1.67 1.676Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.5' x2='4.841' y1='4.353' y2='4.353' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='.5' x2='4.841' y1='4.353' y2='4.353' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        width: 5px;
        height: 5px;
        right: 10px;
        bottom: 20px;
      }
    }
  }
  &--3 {
    .submit-step__icon {
      &::before {
        display: none;
      }
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 5 5'%3E%3Cpath fill='url(%23a)' stroke='url(%23b)' d='M4.34 2.176c0 .928-.748 1.677-1.67 1.677-.92 0-1.67-.75-1.67-1.677S1.75.5 2.67.5c.922 0 1.67.75 1.67 1.676Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.5' x2='4.841' y1='4.353' y2='4.353' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='.5' x2='4.841' y1='4.353' y2='4.353' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

        width: 7px;
        height: 7px;
        left: 5px;
        bottom: 20px;
      }
    }
  }
  &--4 {
    .submit-step__icon {
      &::before {
        width: 8px;
        height: 8px;
        left: 3px;
        bottom: 30px;
      }
      &::after {
        width: 14px;
        height: 14px;
        right: -14px;
        top: 35px;
      }
    }
  }
  &--5 {
    .submit-step__icon {
      &::before {
        width: 8px;
        height: 8px;
        bottom: 15px;
        right: 0;
      }
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 5 5'%3E%3Cpath fill='url(%23a)' stroke='url(%23b)' d='M4.34 2.176c0 .928-.748 1.677-1.67 1.677-.92 0-1.67-.75-1.67-1.677S1.75.5 2.67.5c.922 0 1.67.75 1.67 1.676Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.5' x2='4.841' y1='4.353' y2='4.353' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='.5' x2='4.841' y1='4.353' y2='4.353' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        width: 5px;
        height: 5px;
        left: -2.5px;
        top: 45px;
      }
    }
  }
  &--6 {
    .submit-step__icon {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 11 11'%3E%3Cpath fill='none' stroke='url(%23a)' d='m5.079 1.564 5.222 6.9a.5.5 0 0 1-.336.798l-8.57 1.085a.5.5 0 0 1-.524-.69l3.348-7.984a.5.5 0 0 1 .86-.11Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='-.75' x2='13.11' y1='14.652' y2='12.907' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        width: 14px;
        height: 14px;
        bottom: 10px;
        left: 10px;
      }
      &::after {
        display: none;
      }
    }
  }
  &--7 {
    &::before {
      position: absolute;
      content: "";
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 5 5'%3E%3Cpath fill='url(%23a)' stroke='url(%23b)' d='M4.34 2.176c0 .928-.748 1.677-1.67 1.677-.92 0-1.67-.75-1.67-1.677S1.75.5 2.67.5c.922 0 1.67.75 1.67 1.676Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.5' x2='4.841' y1='4.353' y2='4.353' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='.5' x2='4.841' y1='4.353' y2='4.353' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

      width: 4px;
      height: 4px;
      top: 40px;
      right: calc(50% - 70px);
    }
    .submit-step__icon {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 7 7'%3E%3Cpath fill='url(%23a)' d='M6.651 4.269a1 1 0 0 1-.62 1.616l-4.452.706A1.002 1.002 0 0 1 .49 5.243l1.616-4.22a.998.998 0 0 1 1.71-.268l2.836 3.514Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.8' x2='-2.516' y1='-2' y2='6.605' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

        width: 13px;
        height: 13px;
        top: -6px;
      }
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 9 8'%3E%3Cpath fill='%23fff' stroke='url(%23a)' d='M7.597 3.809a3.304 3.304 0 0 1-3.299 3.309A3.304 3.304 0 0 1 1 3.808 3.304 3.304 0 0 1 4.298.5c1.82 0 3.299 1.48 3.299 3.309Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.5' x2='8.097' y1='7.618' y2='7.618' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

        width: 8px;
        height: 8px;
        left: -10px;
        bottom: 20px;
      }
    }
  }
}

.section-criteria {
  margin-bottom: 100px;
  .section__title {
    @include up(1200) {
      margin-bottom: 10px;
    }
  }

  p {
    margin-top: 20px;
  }
}

.icon-wrapper {
  border-radius: 50%;
  background: white;
  width: 100px;
  height: 100px;
  display: grid;
  place-items: center;
  box-shadow: 0px 10px 30px #eef1f7;
  transition: all 250ms;
  svg {
    width: 75px;
    height: 75px;
  }
}

.nav-criteria {
  margin-top: 30px;
  margin-bottom: 36px;
  justify-content: center;
  gap: 26px;
  @include up(1200) {
    justify-content: space-between;
    margin-top: 82px;
    margin-bottom: 60px;
  }
  .nav-link {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 16px;
    background: none;
    border: 0;
    color: var(--color-title);
    background: transparent;
    padding: 0;

    .icon-wrapper {
      @include up(1200) {
        width: 130px;
        height: 130px;
      }
      svg {
        --icon-stroke-color: var(--color-title);
        .icon-fill-stroke {
          fill: var(--icon-stroke-color);
        }
        .icon-stroke {
          stroke: var(--icon-stroke-color);
        }
      }
    }
    span {
      font-family: var(--font-title);
      font-size: var(--font-size-h4);
      line-height: 1.1;
      display: none;
      @include up(1200) {
        display: inline;
      }
    }
    &:hover,
    &.active {
      .icon-wrapper {
        background: linear-gradient(90deg, #539f84 0%, #289db4 100%);

        svg {
          --icon-stroke-color: white;
        }
      }
      span {
        transition: all 250ms;
        background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
}

.tab-criteria {
  box-shadow: 0px 10px 30px #eef1f7;
  border-radius: 10px;
  .tab-header {
    border-radius: 10px 10px 0px 0px;
    position: relative;
    img {
      min-height: 194px;
      object-fit: cover;
    }
    &__content {
      position: absolute;
      z-index: 2;
      top: 36px;
      padding: 0 20px;
      @include up(1200) {
        padding: 0 130px;
      }
      color: white;
    }
    &::after {
      z-index: 1;
      background: radial-gradient(
        91.38% 91.38% at 95.07% 13.57%,
        #ffffff 0%,
        #289db4 100%,
        #289db4 100%
      );
      mix-blend-mode: darken;
      opacity: 0.4;
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      position: absolute;
      left: 0;
    }

    h2,
    h5 {
      color: white;
    }

    h2 {
      margin-bottom: 20px;
    }

    h5 {
      max-width: 40ch;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
    li {
      margin-bottom: 20px;
      display: flex;
      gap: 20px;
      align-items: center;

      @include up(1200) {
        max-width: 84ch;
      }
      &::before {
        flex-shrink: 0;
        font-weight: bold;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Ccircle cx='10' cy='10' r='9' fill='%23fff' stroke='url(%23a)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='20' y1='20' y2='20' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23539F84'/%3E%3Cstop offset='1' stop-color='%23289DB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }
    }
  }

  .tab-content {
    padding: 20px 20px 20px;
    @include up(1200) {
      padding: 50px 130px 40px;
    }
  }
}

#listCriteria {
  li:nth-child(n + 6) {
    display: none;
  }

  &.show {
    li:nth-child(n + 6) {
      display: flex;
    }
  }
}

.section-submit-form {
  margin-bottom: 80px;
  .section__title {
    z-index: 1;
  }
}

.card-submit-form {
  color: var(--color-title);
  margin-top: 13px;
  padding: 45px;
  border: none;

  @include up(1200) {
    margin-top: 26px;
    padding: 90px;
  }

  .form-filter-radio-input:checked + label {
    .icon-wrapper {
      background-color: $primary;
      svg {
        stroke: white;
      }
    }
  }

  .form-filter-radio-label {
    .icon-wrapper {
      min-width: 79px;
      height: 79px;
      flex: 0 0 79px;
      svg {
        height: 60px;
        width: auto;
      }
    }
  }

  .filter-row {
    @include up(1200) {
      gap: 42px;
      justify-content: center;
    }
  }
}

.objectives-odd {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  margin-top: 40px;
}

.objective-odd {
  flex-basis: calc(50% - 7.5px);
  @include up(480) {
    flex-basis: initial;
  }
  label {
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 250ms ease-in-out;
  }
  input {
    display: none;
  }

  label:hover,
  input:checked + label {
    opacity: 1;
  }

  &:last-of-type {
    label:hover {
      opacity: 0.5;
    }
  }
}

.is-pme {
  display: none;

  &.visible {
    display: block;
  }
}
