.profile-form {
  --fc-neutral-bg-color: transparent;
  --fc-border-color: #ededee;
  --fc-today-bg-color: hsla(159, 31%, 47%, 0.1);
  --fc-list-event-hover-bg-color: transparent;
  #calendar {
    position: relative;
    margin-top: 20px;
    box-shadow: 0px 10px 30px #eef1f7;
    border-radius: 0px 0px 10px 10px;
    background: white;
    @include up(768) {
      margin-top: 122px;
    }
  }

  .fc-scroller-liquid{
    margin: 40px 0 0;
    padding: 0 50px 0 0;
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
      background-color: #F5F5F5;
      border-radius: 6px;
    }
    &::-webkit-scrollbar
    {
      width: 6px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb
    {
      border-radius: 6px;
      background-color: $primary;
    }
  }

  .fc .fc-toolbar.fc-header-toolbar {
    flex-direction: row-reverse;
    justify-content: center;
    background: #ededee;
    border-radius: 10px 10px 0px 0px;
    padding: 20px 45px;
    margin-bottom: 0;

    @include up(1200) {
      height: 89px;
    }

    .fc-toolbar-chunk:nth-child(1) {
      margin-left: auto;
    }
    .fc-toolbar-chunk:nth-child(2) {
      margin-left: auto;
    }
    .fc-toolbar-chunk:nth-child(3) {
      position: absolute;
      top: -92px;
      right: 0;

      //switch

      .fc-button-group {
        margin-left: auto;

        position: relative;
        overflow: hidden;
        width: fit-content;
        border-radius: 44px;

        display: none;
        @include up(768) {
          display: block;
        }
        .fc-button {
          display: inline-block;
          position: relative;
          text-align: center;

          padding: 18px 33px 18px 47px;
          text-transform: uppercase;
          font-weight: 700;
          font-family: var(--font-title);
          font-size: 16px;
          color: white;
          border: 0;
          background: transparent;
        }

        .fc-dayGridMonth-button {
          padding-left: 43px;
          padding-right: 45px;
        }

        .fc-button:before,
        .fc-button:after {
          content: "";
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: -2;
        }
        .fc-button:before {
          background: #d9d9d9;
          // border-radius-top-right: 44px;
          border-top-left-radius: 44px;
          border-bottom-left-radius: 44px;
        }

        .fc-dayGridMonth-button:before {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 44px;
          border-bottom-right-radius: 44px;
        }

        .fc-button:after {
          background: linear-gradient(90deg, $primary 0%, #289db4 100%);
          border-radius: 44px;

          transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
        }
        .fc-listYear-button {
          border-right: 0;
        }
        .fc-listYear-button:after {
          left: 100%;
        }
        // .fc-dayGridMonth-button {
        //   margin-left: -5px;
        // }
        .fc-dayGridMonth-button:after {
          left: -100%;
        }
        .fc-button[aria-pressed="true"] {
          cursor: default;
          color: #fff;
          transition: color 200ms;
        }
        .fc-button[aria-pressed="true"]:after {
          left: 0;
        }
      }
    }
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  .fc .fc-toolbar-title {
    color: var(--color-title);
    word-break: break-all;
    display: block;
    white-space: pre-line;
    @include up(1200) {
      font-size: 26px;
    }
  }
  .fc .fc-prev-button,
  .fc .fc-next-button {
    background: transparent;
    border: 0;
    font-size: 0;

    &::before {
      content: "";
      display: inline-block;
      width: 19px;
      height: 21px;
      transition: background 250ms;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 19 21'%3E%3Cpath fill='%232B3447' fill-rule='evenodd' stroke='%232B3447' stroke-linecap='round' d='M16.832 8.75c1.377.76 1.377 2.74 0 3.5L3.967 19.36C2.634 20.097 1 19.133 1 17.61V3.39C1 1.867 2.634.903 3.967 1.64l12.866 7.11Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
    }

    &:hover {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 19 21'%3E%3Cpath fill='%23539f84' fill-rule='evenodd' stroke='%23539f84' stroke-linecap='round' d='M16.832 8.75c1.377.76 1.377 2.74 0 3.5L3.967 19.36C2.634 20.097 1 19.133 1 17.61V3.39C1 1.867 2.634.903 3.967 1.64l12.866 7.11Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
      }
    }
  }
  .fc .fc-prev-button {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 19 21'%3E%3Cpath fill='%232B3447' fill-rule='evenodd' stroke='%232B3447' stroke-linecap='round' d='M2.168 8.75c-1.377.76-1.377 2.74 0 3.5l12.865 7.11c1.333.737 2.967-.227 2.967-1.75V3.39c0-1.523-1.634-2.487-2.967-1.75L2.168 8.75Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
    }
    &:hover {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 19 21'%3E%3Cpath fill='%23539f84' fill-rule='evenodd' stroke='%23539f84' stroke-linecap='round' d='M2.168 8.75c-1.377.76-1.377 2.74 0 3.5l12.865 7.11c1.333.737 2.967-.227 2.967-1.75V3.39c0-1.523-1.634-2.487-2.967-1.75L2.168 8.75Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .fc-button-group {
    gap: 30px;
  }

  .fc .fc-button-group > .fc-button.fc-button-active,
  .fc .fc-button-group > .fc-button:active,
  .fc .fc-button-group > .fc-button:focus,
  .fc .fc-button-group > .fc-button:hover {
    background: transparent;
    z-index: initial;
    box-shadow: none !important;
  }

  .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    margin-left: 0;
    border-radius: initial;
  }

  .fc .fc-view-harness-active > .fc-view {
    padding: 10px 10px 25px 10px;

    @include up(1200) {
      padding: 38px 35px 55px 35px;
    }
  }

  .fc-theme-standard .fc-scrollgrid {
    border: 0;
  }

  .fc .fc-daygrid-day-top {
    flex-direction: row;
  }

  //borders

  .fc .fc-col-header-cell {
    border: 0;
  }

  .fc-scrollgrid-section.fc-scrollgrid-section-body {
    > td {
      border: 1px solid var(--fc-border-color);
    }
  }

  .fc .fc-scrollgrid-section.fc-scrollgrid-section-header > th {
    border: 0;
    padding-bottom: 15px;

    @include up(1200) {
      padding-bottom: 33px;
    }
  }

  //cells

  .fc .fc-col-header-cell-cushion,
  .fc .fc-daygrid-day-number {
    font-family: var(--font-title);

    font-weight: 700;
    line-height: 1.2;

    color: var(--color-title);
    text-decoration: none;
    text-transform: capitalize;

    @include up(1200) {
      font-size: 20px;
    }
  }

  .fc .fc-daygrid-day-number {
    @include up(1200) {
      padding-left: 17px;
      padding-top: 7px;
    }
  }

  .fc .fc-day-today .fc-daygrid-day-number {
    color: $primary;
  }

  .fc-list-event,
  .fc-h-event {
    background: transparent;
    border: 0;
    border-radius: 0;

    &.paid {
      border-color: $primary;
    }

    .fc-event-main{
      position: absolute;
      display: none;
      background: white;
      top: calc(100% + 10px);
      right: 0;
      border-radius: 5px;
      color: var(--color-title);
      font-weight: 700;
      z-index: 9999;
      width: 250px;
      overflow: auto;
      padding: 10px;
      box-shadow: 0px 10px 40px rgba(209, 220, 242, 0.8);
      .status {
        word-break: break-word;
        white-space: break-spaces;
        line-height: 14px;
        font-weight: 400;
        margin: 5px 0;
      }
    }

    &:hover{
      cursor: pointer;
      z-index: 999;
      .fc-event-main{
        display: block;
      }
    }

  }

  .fc-h-event {
    padding: 0;
    margin: 0 !important;
    margin-bottom: 5px !important;
    position: relative;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    // &::before {
    //   position: relative;
    //   display: inline-block;
    //   background: $primary;
    //   height: 100%;
    //   width: 12px;
    //   content: "";
    // }
    border-left: 12px solid $primary;
    .fc-event-title {
      color: var(--color-title);
      font-weight: 700;
      font-size: 16px;
    }

    .fc-event-content {
      color: var(--color-title);
      font-size: 18px;
      font-weight: 700;
    }
    .status {
      display: block;
      color: var(--color-title);
    }
    &.paid{
      .status{
        color: $primary;
      }
    }
  }

  // List view

  .fc .fc-view-harness-active > .fc-list {
    padding: 10px 10px 25px 10px;

    @include up(1200) {
      padding: 0 90px 80px 90px;
    }
  }

  .fc .fc-list-day {
    // &.fc-day-past {
    //   opacity: 0.5;
    // }
  }

  .fc .fc-list-day > th {
    width: 100%;
    border-top: 2px solid #ededee;
  }

  .fc .fc-list-day-cushion {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;

    @include up(1200) {
      padding-top: 50px;
      padding-bottom: 30px;
    }
  }

  .fc-list-table tbody tr:first-of-type{
    .fc-list-day-cushion {
      @include up(1200) {
        padding-top: 10px;
      }
    }
  }

  .fc .fc-list-day-text {
    text-decoration: none;
    font-family: var(--font-title);
    font-weight: 700;

    @include up(1200) {
      font-size: 26px;
      padding-right: 15px;
    }
  }

  .fc .fc-list-event {
    // .fc-list-event-graphic,
    cursor: default;
    td {
      border: 0;
      vertical-align: middle;
    }
    .fc-list-event-title {
      padding-left: 20px;
    }
    .fc-event-content {
      white-space: nowrap;
    }
    .fc-list-event-title,
    .fc-event-content {
      @include up(1200) {
        padding: 20px 10px;
      }
    }
    .fc-list-event-graphic {
      padding: 0 15px 0 0;

    }

    .fc-list-event-dot {
      border-radius: 0;
      border-color: $primary;
      width: 18px;
      display: block;
      height: 56px;
      background-color: $primary;
      box-sizing: border-box;
    }

    .fc-list-event-time {
      display: none;
    }

    .fc-list-event-title {
      a {
        color: var(--color-title);
        font-family: var(--font-title);
        font-weight: 700;
        background: none;
        -webkit-text-fill-color: initial;
        display: inline-block;
        vertical-align: middle;
        @include up(1200) {
          font-size: 26px;
        }
      }
    }

    .status {
      height: 14px;
      display: flex;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      font-family: var(--font-general);
   
      vertical-align: middle;

      @include up(1200) {
        //margin-left: 20%;
      }
    }

    &.paid{
      .status{
        color: $primary;
      }
    }

    .fc-event-content {
      font-family: var(--font-title);
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      text-align: right;
      @include up(1200) {
        font-size: 26px;
      }
    }

    &.paid {
      .fc-list-event-dot {
        border-color: $primary;
        background-color: $primary;
      }
    }

    &.special{
      .fc-list-event-dot {
        border-radius: 0;
        border-right: 5px $yellow solid !important;
      }
    }
  }
}

.fc .fc-scroller-harness-liquid,
.fc .fc-scroller-liquid-absolute{
  overflow: visible !important;
}

.fc-daygrid-event{
  .fc-event-content{
    .fineTooltip{
      display: inline-block;
    }
  }
  .fc-event-main{
    width: auto !important;
    .status{
      .fineTooltip{
        display: none;
      }
    }
  }
}

.fc .fc-list-event,
.fc-daygrid-event{
  &.stat_2{
    border-color: #4A6AB5;
    .fc-list-event-dot{
      background: #4A6AB5;
      border-color: #4A6AB5;
    }
  }

  &.stat_3{
    border-color: #4F99A9;
    .fc-list-event-dot{
      background: #4F99A9;
      border-color: #4F99A9;
    }
  }

  &.stat_6{
    border-color: #54B376;
    .fc-list-event-dot{
      background: #54B376;
      border-color: #54B376;
    }
  }

  &.stat_7{
    border-color: #50AB58;
    .fc-list-event-dot{
      background: #50AB58;
      border-color: #50AB58;
    }
  }

  &.stat_8{
    border-color: #54B5AD;
    .fc-list-event-dot{
      background: #54B5AD;
      border-color: #54B5AD;
    }
  }

  &.stat_9{
    .fc-list-event-dot{
      background: #EDB82C;
      border-color: #EDB82C;
    }
  }

  &.stat_4{
    border-color: #EDB82C;
    .fc-list-event-dot{
      background: #EDB82C;
      border-color: #EDB82C;
    }
  }

  &.stat_10{
    border-color: #E8A5A6;
    .fc-list-event-dot{
      background: #E8A5A6;
      border-color: #E8A5A6;
    }
  }

  &.stat_11{
    border-color: #E87476;
    .fc-list-event-dot{
      background: #E87476;
      border-color: #E87476;
    }
  }

  &.stat_12{
    border-color: #E6413D;
    .fc-list-event-dot{
      background: #E6413D;
      border-color: #E6413D;
    }
  }

  &.stat_5{
    border-color: #E6413D;
    .fc-list-event-dot{
      background: #E6413D;
      border-color: #E6413D;
    }
  }
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
  min-height: 4em !important;
}