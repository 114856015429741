.page-jobs {
  .section__title {
    margin-bottom: 10px;
  }

  h3.noffset-gutter {
    @include up(1200) {
      margin-bottom: 100px;
    }
  }

  .section-jobs {
    .col-xxl-5 {
      @include up(1400) {
        margin-left: calc((var(--bs-gutter-x) * -1) + 8.333333%);
        p {
          max-width: 48ch;
        }
      }
    }

    .col-xxl-6 {
      @include up(1400) {
        margin-left: auto;
      }
    }
  }
}

.card-job {
  padding: 10px 20px;
  color: var(--color-title);

  @include up(1200) {
    padding: 29px 40px;
  }

  .btn-job {
    display: flex;
    // box-shadow: 0px 10px 30px #eef1f7;
    // border-radius: 10px;
    // padding: 29px 40px;
    &[aria-expanded="true"] {
      span {
        color: var(--primary);
      }

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 9'%3E%3Cpath fill='%23539F84' fill-rule='evenodd' d='m14.077.774 1.179 1.12L8 8.785.744 1.893 1.923.773 8 6.548 14.077.774Z' clip-rule='evenodd'/%3E%3C/svg%3E");
      }
    }

    div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-weight: 700;
      width: 100%;
      @include up(1200) {
        align-items: center;
        flex-direction: row;
      }
    }
  }

  &__body {
    padding-top: 40px;
    @include up(1200) {
      max-width: calc(70% - var(--bs-gutter-x));
    }
  }
}
