.document-listing {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  margin-top: 30px;
  gap: 35px;
  @include up(1200) {
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
    gap: 70px 16.66666667%;
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h3 {
      color: var(--primary);
      margin-bottom: 0.278em;
      margin-left: var(--bs-gutter-x);
    }

    p {
      margin-left: var(--bs-gutter-x);
    }
  }
}

.document {
  background: #ffffff;

  box-shadow: 0px 10px 30px #eef1f7;
  border-radius: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 42px 1fr 22px;
  gap: 20px;
  line-height: 1.2;
  padding: 15px 20px 15px 20px;

  @include up(1200) {
    gap: 30px;
    padding: 20px 39px 20px 42px;
  }

  &.document--download {
    grid-template-columns: 42px 1fr 22px 22px;
  }

  &__title {
    font-weight: 700;
    display: block;
    font-family: var(--font-title);
  }

  &__file {
    font-size: 16px;
    font-weight: 600;
  }
}

.btn-add-document {
  svg {
    transition: 250ms transform;
    backface-visibility: hidden;
  }
  &:hover {
    svg {
      transform: scale(1.05);
    }
  }
}
.btn-show-document {
  svg {
    transition: 250ms transform;
    backface-visibility: hidden;

    path {
      transition: 250ms stroke;
    }
  }
  &:hover {
    svg {
      transform: scale(1.1);

      path {
        stroke: var(--primary);
      }
    }
  }
}
.btn-download-document {
  svg {
    transition: 250ms transform;
    backface-visibility: hidden;

    path {
      transition: 250ms stroke;
    }
  }
  &:hover {
    svg {
      transform: scale(1.1);

      path {
        stroke: var(--primary);
      }
    }
  }
}
