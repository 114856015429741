.table-wrapper {
  box-shadow: 0px 10px 30px #eef1f7;
  border-radius: 10px;
  margin-bottom: 60px;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  @include up(1200) {
    overflow-x: visible;
  }
  
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  margin-bottom: 0;
}
.table thead {
  background: #ededee;
}

.table thead th {
  vertical-align: middle;
  text-align: center;
  font-weight: 700;
  font-family: var(--font-title);
  padding-top: 10px;
  padding-bottom: 10px;
  @include up(1200) {
    padding-top: 30px;
    padding-bottom: 30px;
    white-space: nowrap;
  }
}
.table td,
.table th {
  text-align: center;
  padding: 0 2rem;
  vertical-align: middle;
  @include up(1200) {
    padding: 0.75rem;
  }
}

.table th {
  font-weight: 400;
}
.table tbody tr:last-of-type {
  background: #539f84;
  color: white;

  th,
  td {
    font-weight: 700;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  th {
    font-family: var(--font-title);
  }

  td {
    //white-space: nowrap;
  }
}
