@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  src: local("Montserrat"),
    url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-family: "Montserrat SemiBold";
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold"),
    url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

:root {
  --font-title: "Montserrat", Helvetica, sans-serif;
  --font-general: "Open Sans", Helvetica, sans-serif;
  --font-filters: "Roboto", Helvetica, sans-serif;

  // Font size : https://min-max-calculator.9elements.com/
  // 16px[400] -> 20px[1920]
  --font-size-body: clamp(1rem, 0.934rem + 0.26vw, 1.25rem);
  // 16px[400] -> 14px[1920]
  --font-size-small: clamp(0.875rem, 0.842rem + 0.13vw, 1rem);

  // 24px[400] -> 56px[1920]
  --font-size-h1: clamp(1.5rem, 0.974rem + 2.11vw, 3.5rem);
  // 24px[400] -> 46px[1920]
  --font-size-h2: clamp(1.5rem, 1.138rem + 1.45vw, 2.875rem);
  // 22px[400] -> 36px[1920]
  --font-size-h3: clamp(1.375rem, 1.145rem + 0.92vw, 2.25rem);
  // 20px[400] -> 26px[1920]
  --font-size-h4: clamp(1.25rem, 1.151rem + 0.39vw, 1.625rem);
  // 16px[400] -> 20px[1920]
  --font-size-h5: var(--font-size-body);
}

body {
  color: #2b3447;
  font-family: var(--font-general);
  -webkit-font-smoothing: antialiased;
  // -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-body);
}

a {
  color: var(--primary);

  &:hover {
    color: darken($primary, 10%);
  }
}

main a:not([class]),
main .modal a:not(.btn-main).actionModal {
  background: linear-gradient(
    90deg,
    #539f84 0%,
    #539f84 50%,
    #6db29b 50%,
    $yellow 100%
  );
  background-size: 200% auto;
  background-position: 0 0;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 250ms background-position;
  text-decoration: none;
  &:hover {
    background-position: 100% 0;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-title);
  color: var(--color-title);
  line-height: 1.1;
  font-weight: 700;
}
h1 {
  font-size: var(--font-size-h1);
}
h2 {
  font-size: var(--font-size-h2);
  margin-bottom: 0.652em;
}
h3 {
  font-size: var(--font-size-h3);

  p {
    margin-bottom: 0;
  }
}
h4 {
  font-size: var(--font-size-h4);
  margin-bottom: 40px;
}
h5 {
  font-size: var(--font-size-h5);
  line-height: 1.2;
  margin-bottom: 1.25em;
  color: $primary;
}

p {
  margin-bottom: 1.5em;
  /*line-height: 1.375;
  line-height: calc(-3px + 2ex + 8.1px);*/
  --p-lineheight: clamp(3.125rem, 0.164rem + calc(11.84vw), 14.375rem);
  line-height: var(--section-title-fontsize);
}

.section__title {
  // 50px[400] -> 230px[1920]
  --section-title-fontsize: clamp(3.125rem, 0.164rem + 11.84vw, 14.375rem);
  --section-height: calc(var(--section-title-fontsize) * 1.2174);
  --section-title-vertical-offest: clamp(1.75rem, 0.599rem + 4.61vw, 6.125rem);

  width: 100vw;
  height: var(--section-height);
  position: relative;
  top: 0;
  z-index: -1;

  &__spaceTop {
    @include up(1200) {
      top: 120px;
    }
  }

  @include up(768) {
    top: var(--section-title-vertical-offest);
    margin-top: calc(var(--section-title-vertical-offest) * -1);
  }

  @include up(1200) {
    left: -35px;
  }
  @include up(1920) {
    left: -60px;
  }
}

.section__title-text {
  font-family: var(--font-title);
  font-weight: 700;
  font-size: var(--section-title-fontsize);
  line-height: 1;
  color: #fff;
  position: relative;
  stroke-width: 1;
  stroke: url(#gradient);
  fill: none;

  dominant-baseline: central;
  paint-order: stroke;

  @include up(1200) {
    stroke-width: 2;
  }
}

.mainContent {
  margin-bottom: 120px;

  @include up(1200) {
    margin-top: 140px;
  }
}

.primary {
  color: var(--primary);
}

.capitalize {
  text-transform: uppercase;
  font-weight: 700;
}

ul.mission {
  list-style: none;
  li {
    &::before {
      display: inline-block;
      width: 1em;
      margin-left: -1em;

      font-weight: bold;

      content: "\2022";
      font-size: 1.25rem;
      font-family: Arial, Helvetica, sans-serif;

      color: #539f84;
    }

    ul {
      padding-top: 12px;
      padding-bottom: 12px;
      li::before {
        content: "\25CB";
      }
    }
  }
}

.green {
  color: #539f84 !important;
}

.yellow {
  color: $yellow !important;
}

svg.project-item__icon {
  stroke: #2b3447;
}

.text-help {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4;
}

.nowrap {
  white-space: nowrap;
}


.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}