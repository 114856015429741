@media (min-width: 992px) {
  .modal-lg,
  .modal-xl,
  .modal-xxl {
    --bs-modal-width: 600px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 800px;
  }
  .modal-xxl {
    --bs-modal-width: 70vw;
  }
}


#eccoModal {
  .modal-dialog {
    max-width: 90%;
  
    @media (max-width: 1199px) {
      width: auto;
      margin: 80px auto;
    }
    @media (min-width: 1200px) {
      width: fit-content;
      min-width: 800px;
    }
    .modal-header {
      padding: 0;
      margin-bottom: 0px;
    }
  }
}

.modal-header {
  border: 0;
  margin-bottom: 20px;
  padding-left: 0;
  &.none {
    padding: 0;
    margin: 0;
  }
  .btn-close {
    z-index: 2;
    position: absolute;
    margin: 0;
    top: 18px;
    @media (max-width: 1199px) {
      top: -60px;
      right: 0;
    }
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8.5px;
    right: 20px;
    background: #ffffff;
    box-sizing: border-box;

    box-shadow: 0px 10px 40px rgba(209, 220, 242, 0.8);
    border: 0;
    border-radius: 50%;
    // width: 38px;
    // height: 38px;

    svg {
      width: 21px;
      height: 21px;
    }
  }
}

.modal-content {
  border-radius: 0;
  padding: 20px;
  @include up(1200) {
    padding: 74px 85px 22px 85px;
  }

  #eccoModal & {
    padding: 0px;
  }

  .form-group {
    @include up(1200) {
      margin-bottom: 40px;
    }
  }

  a:not([class]),
  .modal a:not(.btn-main).actionModal {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .form-check-label {
    font-size: 16px;
  }
}

.modal-body {
  padding: 0;
  &--info{
    p,li{
      font-size: 16px;
    }
  }
}

.modal-xl {
  .modal-content {
    @include up(1200) {
      padding: 74px 62px 22px 80px;
    }
  }
}
