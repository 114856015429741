// typography
.gradient {
  background: linear-gradient(90deg, #539f84, #289db4);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // display: inline-block;
}

.loan-intro {
  position: relative;
  .bg-icon {
    --bg-icon-rotate-start: -15deg;
    width: 500px;
    height: 500px;
    right: -330px;
    bottom: -320px;
    transform: rotate(var(--bg-icon-rotate-start));
    z-index: -1;
  }
  margin-bottom: 60px;

  @include up(1200) {
    margin-bottom: 126px;
  }
  .section__title {
    z-index: 0;
    @include up(1200) {
      left: calc(-16.6667%);
    }
  }

  h3 {
    position: relative;
    z-index: 1;
    @include up(1200) {
      margin-bottom: 35px;
      margin-top: 10px;
    }
  }

  .buttons-wrapper {
    @include up(1200) {
      margin-top: 47px;
    }
  }

  img {
    position: relative;
    z-index: -1;
  }

  p {
    @include up(1200) {
      max-width: 60ch;
    }
  }
}

.loan-solutions {
  &__intro {
    margin-bottom: 70px;
    @include up(1200) {
      margin-bottom: 90px;
    }
  }
}

.solution {
  @include up(1200) {
    padding-top: 64px;
  }

  &__title {
    display: flex;
    margin-bottom: 20px;
    gap: 20px;

    @include up(1200) {
      gap: 40px;
      margin-bottom: 80px;
    }
    .icon {
      stroke: $black;
      flex-shrink: 0;
    }

    h2 {
      margin-bottom: 0.348em;
    }
  }

  & &__subtitle {
    font-family: var(--font-size-h5);
    font-weight: 700;
    font-family: var(--font-title);
    max-width: 40ch;
    display: block;
  }

  &--pme {
    position: relative;
    .bg-icon {
      --bg-icon-rotate-start: -15deg;
      width: 600px;
      height: 600px;
      left: -330px;
      top: 0;
      transform: rotate(var(--bg-icon-rotate-start));
      z-index: -1;
    }
  }
}

.loans {
  @include up(1200) {
    gap: var(--bs-gutter-x);
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  &--by-2 {
    @include up(1200) {
      grid-template-columns: repeat(2, 1fr);
    }
    .loan {
      @include up(1200) {
        padding: 52px 45px 57px 71px;
      }
      &__infos {
        @include up(1640) {
          // justify-content: space-between;
          // flex-wrap: nowrap;

          display: grid;
          grid-template-columns: 35% 26% 39%;
        }
      }
      &__footer a {
        @include up(1200) {
          width: 480px;
        }
      }
    }
  }
  &--by-3 {
    @include up(1200) {
      grid-template-columns: repeat(3, 1fr);
    }
    .loan {
      &__infos {
        @include up(990) {
          flex-direction: column;
        }
      }
      &__footer a {
        display: block;
      }
    }
  }
}

.loan {
  --p-lineheight: 16px;
  box-shadow: 0px 10px 30px #eef1f7;
  border-radius: 10px;
  padding: 19px;

  display: flex;
  background: white;
  flex-direction: column;

  transition: transform 300ms;

  position: relative;
  overflow: hidden;

  @include up(1200) {
    padding: 38px;
  }

  @include down(576) {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: var(--bs-gutter-x);
  }

  &__banner{
    background-color: $yellow;
    box-shadow: 0px 5px 10px #eef1f7;
    color: white;
    font-weight: 800;
    font-size: 14px;
    padding: 5px 10px;
    transform: rotate(45deg);
    display: inline-block;
    position: absolute;
    right: -55px;
    top: 20px;
    width: 180px;
    text-align: center;
  }

  &__title {
    color: #539f84;
    margin-bottom: 0.625em;
    // 24px[400] -> 32px[1920]
    /* --loan-title-fontsize: clamp(3.125rem, 0.164rem + 11.84vw, 14.375rem);
    font-size: var(--loan-title-fontsize);*/
    font-size: 32px;
    line-height: 110%;
    @include up(1200) {
      min-height: 70px;
    }
  }
  &__subtitle {
    margin-bottom: 10px;
  }
  &__description {
    margin-bottom: 38px;
    line-height: 1.7;
  }
  &__infos {
    display: flex;
    margin-top: auto;
    flex-wrap: wrap;
    gap: 0;
    margin-bottom: 22px;
  }
  &__meta {
    display: flex;
    align-items: center;

    .icon {
      flex-shrink: 0;
    }

    &-value {
      font-size: var(--font-size-h5);
      font-weight: 700;
      font-family: var(--font-title);
      display: block;
      line-height: 1;
      white-space: nowrap;
    }

    &-label {
      font-size: 16px;
      line-height: 1.4;
      display: block;
    }
  }
  &__footer {
    text-align: center;
  }
  &:hover {
    transform: translateY(-5px);
  }
}

.loan-advantages {
  position: relative;

  .bg-icon {
    --bg-icon-rotate-start: -15deg;
    width: 600px;
    height: 600px;
    right: 0;
    top: 0;
    transform: rotate(var(--bg-icon-rotate-start));
    z-index: -1;
  }

}

.advantages-panels {
  //padding: 0 calc(8.3333% - var(--bs-gutter-x));
  padding: 0 calc(5% - var(--bs-gutter-x));
  margin-top: 70px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include up(1200) {
    gap: 25px;
    grid-template-columns: repeat(3, 1fr);
  }
  @include up(1560) {
    padding: 0 calc(8.3333% - var(--bs-gutter-x));
  }
}

.loan-questions {
  margin-bottom: 150px;
  position: relative;
  .bg-icon {
    --bg-icon-rotate-start: -15deg;
    width: 600px;
    height: 600px;
    left: -540px;
    top: -240px;
    transform: rotate(var(--bg-icon-rotate-start));
    z-index: -1;
  }
  .section__title {
    z-index: 1;
    margin-bottom: -30px;
  }
}

.tel-form {
  position: relative;
  input {
    height: 60px;
    width: 100%;
    box-shadow: 0px 10px 30px #eef1f7;
    border-radius: 3px;
    border: 1px solid #f3f4f6;
    padding-left: 40px;
    padding-right: 56%;
  }

  button {
    position: absolute;
    right: 0;
    border-radius: 0;
    padding-right: 42px;
    padding-left: 42px;
  }
}
