.sidebar-steps {
  position: sticky;
  margin: auto;
  z-index: 5;
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 40px rgba(209, 220, 242, 0.8);
  border-radius: 10px;

  top: 50px;
  padding: 15px 20px;
  margin-bottom: 50px;

  @include up(768) {
    padding: 40px 45px;
  }
  @include up(1440) {
    padding: 40px 90px;
  }

  .btn-collapse {
    font-weight: 700;
    color: var(--color-title);

    @include up(768) {
      display: none;
    }
  }

  .collapse {
    @include up(768) {
      display: block;
    }
  }

  nav {
    margin-top: 20px;
    display: flex;
    gap: 12px;
    @include up(768) {
      gap: 22px;
      margin-top: 0;
    }
    flex-direction: column;
  }

  .sidebar-step {
    font-family: var(--font-title);
    font-weight: 700;
    color: var(--color-title);
    opacity: 1;
    text-decoration: none;

    &.inactive {
      opacity: 0.5;
    }
    &.check {
      color: var(--primary);
      opacity: 1;
    }
  }
}

.page-invest {
  
  .investProfileForm{
    h3{
      font-size: 24px;
      .panel--icon{
        width: 40px;
        height: 40px;
        min-width: 40px;
        img{
          height: 60%;
        }
      }
    }
  }

  @include down(1199) {
    margin-bottom: 60px;
  }
  .btn-secondary {
    //float: right;
  }

  .section__title {
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 1.413em;
    margin-bottom: 1.413em;
  }

  .card {
    border: none;
    color: var(--color-title);
    @include up(1200) {
      padding: 0;
      padding-top: 44px;
      padding-bottom: 44px;
    }

    

    h2 {
      margin-top: 0;
      margin-bottom: 0.783em;
    }
    h3 {
      margin-bottom: 1em;
    }
    h4 {
      margin-bottom: 0.846em;
    }

    hr {
      border-width: 2px;
      border-color: #ededee;
      opacity: 1;
      width: 74%;
      margin-left: 13%;
      margin-top: 45px;
      margin-bottom: 45px;
    }

    article {
      margin-bottom: 60px;
    }
  }
}

//step one

.step-one {
  .card {
    @include up(1200) {
      margin-right: 8.33333%;
    }

    
    color: var(--color-title);

    // h4 {
    //   margin-bottom: 22px;
    // }
    .card-section {
      padding: 0 40px;
      .range {
        margin-left: 60px;
        margin-right: 60px;
        .max_range{
          background-color: $yellow;
          height: 6px;
        }
      }
      .range-steps {
        margin-left: 10px;
        margin-right: 10px;
        @include down(1199) {
          margin-left: 0px;
          margin-right: 0px;
          padding: 0;
        }
      }
    }
    @include down(1199) {
      box-shadow: none;
      .card-section{
        padding: 0px;
        .rangeContainer{
          &:not(.small){
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 0px;
          }
          position: relative;
          #amountReel,strong{
            max-width: none;
            grid-area: 1 / 1 / 2 / 3;
            margin-bottom: 10px;
          }
          strong{
            position: absolute;
            right: 10px;
            top: 10px;
            color: #539f84;
          }
          .btn-range--decrease{
            margin-left: auto;
            grid-area: 2 / 1 / 3 / 2;
          }
          .btn-range--increase{
            grid-area: 2 / 2 / 3 / 3;
          }
        }
        .simuCoinvest{
          margin: 0;
          max-width: none;
          .highlight{
            padding: 20px;
          }
        }
      }
    }

  }

  .table-description {
    font-size: 16px;
  }
  .table-responsive {
    border-radius: 10px;
    margin: 0 40px;
    width: auto;
    border-radius: 10px;
    overflow: hidden;
  }

  .text-help {
    margin-bottom: 48px;
  }
}
.table-amortization {
  *{
    font-size: 16px;
  }
  thead th {
    @include up(1200) {
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: 1.2;
    }

    &:nth-of-type(4) {
      background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
      color: white;
    }
  }
  tr {
    border-style: hidden;
  }
  td {
    padding: 0.5rem 0;
  }

  tbody {
    padding: 33px 0 52px 0;
  }
  tbody tr:last-of-type td {
    font-weight: 400;
  }
  tbody tr th,
  tbody tr td:nth-of-type(3) {
    font-weight: 700;
  }

  tbody tr td:nth-of-type(3) {
    background: linear-gradient(90deg, #539f84, #289db4);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  tbody tr:last-of-type {
    background: initial;
    color: inherit;
  }

  tbody tr:last-of-type th,
  tbody tr:last-of-type td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  tbody tr:first-of-type th,
  tbody tr:first-of-type td {
    padding-top: 33px;
  }
  tbody tr:last-of-type th,
  tbody tr:last-of-type td {
    padding-bottom: 53px;
  }
}

.card-section {
  @include up(1200) {
    padding-left: 45px;
    padding-right: 45px;
  }
  @include up(1440) {
    padding-left: 90px;
    padding-right: 90px;
  }

  &--amortization {
    @include up(1440) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.interests {
  &__label,
  &__value,
  &__costs {
    display: block;
    font-weight: 700;
  }
  &__label {
    font-size: var(--font-size-h4);
  }
  &__value {
    font-size: var(--font-size-h3);
  }
  &__costs {
    font-size: 16px;
    font-weight: 400;
  }
}

@mixin rangeThumb {
  width: 15px;
  height: 15px;
  margin: -4px 0 0;
  border-radius: 50%;

  cursor: pointer;

  background: linear-gradient(90deg, #289db4 0%, #539f84 100%);
  border: 1px solid #ffffff;
}

@mixin rangeTrack {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: transparent;
}

.rangeForm{
  border-radius: 10px;
  margin-bottom: 30px;
  &--limit{
    text-align: center;
    font-weight: 700;
    background: linear-gradient(90deg, #EEB72D 0%, #EEB72D 100%);
  }
  p{
    font-size: 16px;
  }
  h5{
    color: white;
    strong{
      display: block;
      width: fit-content;
      background-color: white;
      border-radius: 10px;
      padding: 0.8125rem 2.5rem;
      color: $black;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
      margin: 15px auto 25px;
    }
  }
}
.rangeForm{
  &.type-7,
  &.type-8{
    .rangeContainer{
      margin-bottom: 25px;
    }
  }
}

.rangeContainer{
  &:not(.small){
    margin-bottom: 45px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
 
  input{
    text-align: center;
    font-family: var(--font-title);
    color: var(--color-title);
    max-width: 430px;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    border-radius: 10px;
    border: 1px solid var(#EDEDEE);
    &:focus{
      color: var(--color-title);
      outline: none;
    }
    @include down(1199) {
      max-width: 200px;
    }
  }
  .btn-range {
    background: white;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    //position: absolute;
    &:hover{
      background-color: #EDEDEE;
    }
    svg{
      width: 16px;
      height: auto;
    }
    @include down(1199) {
      height: 40px;
    }
    /*&--decrease {
      left: -33px;
    }
    &--increase {
      right: -33px;
    }*/
  }
}

.range {
  --percent: 0%;
  position: relative;
  // width: 100%;
  border-radius: 54px;
  background: #ededee;
  padding: 9px 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @include down(1199) {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.range-inner {
  background: linear-gradient(
    90deg,
    #289db4 0%,
    #539f84 var(--percent),
    #ffffff var(--percent),
    #ffffff 100%
  );
  position: relative;
  width: 100%;
  height: 6px;
}

.range input#amount {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  -webkit-appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include rangeThumb;
  }

  &::-moz-range-thumb {
    @include rangeThumb;
  }

  &::-ms-thumb {
    @include rangeThumb;
  }

  &::-webkit-slider-runnable-track {
    @include rangeTrack;
  }

  &::-moz-range-track {
    @include rangeTrack;
  }

  &::-ms-track {
    @include rangeTrack;
  }

  &:focus {
    background: none;
    outline: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
}

.range-steps {
  margin: 0px;
  margin-bottom: 30px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  padding: 0px 42px;

  @include up(1200) {
    margin-left: -17px;
    margin-right: -17px;
    padding: 0 17px;
    margin-bottom: 0px;
  }

  span {
    position: relative;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    width: 46px;
    word-break: keep-all;
    @include up(768) {
      width: 12.5%;
      font-size: 15px;
    }
  }

  .active {
    color: #37adbf;
  }

  .selected::before {
    background: #37adbf;
  }

  .active.selected::before {
    display: none;
  }
}

.range-label {
  position: absolute;
  background: linear-gradient(90deg, #289db4 0%, #539f84 100%);

  /* Ombre 2 - drop sha. Y10-B30 */
  box-shadow: 0px 10px 30px #eef1f7;
  border-radius: 10px;
  color: white;
  font-weight: 700;
  padding: 7px 20px;
  top: -65px;
  left: calc(var(--percent) - 100px);
  @include down(1199) {
    left: calc(var(--percent) - 30px);
    padding: 7px 20px;
    width: auto;
  }
  width: 130px;
  text-align: center;
  // transform: translateX(-50%);
  white-space: nowrap;
  transition: left 25ms;
}

.simuCoinvest{
  text-align: center;
  margin: 40px auto 0;
  max-width: 80%;
  h5{
    color: #ffffff;
  }
  .highlight{
    background-color: white;
    border-radius: 20px;
    color: $black;
    text-align: center;
    padding: 20px 50px;
    margin-bottom: 30px;
    h5{
      color: $black;
      font-size: 26px;
      margin: 0 5px 0 0;
    }
    p{
      margin: 0;
    }
  }
}

.pdfSimulation {
  .card-section {
    position: relative;
    display: block;
    &:after {
      top: 0;
      position: absolute;
      content: none;
      display: block;
      width: calc(100% - 194px);
      height: calc(100% - 90px);
      //background: rgba(209, 220, 242, 0.1);
    }
    &:before {
      top: 35%;
      left: 10%;
      position: absolute;
      content: "Simulation";
      font-size: 115px;
      font-weight: 700;
      font-family: var(--font-title);
      color: $red;
      opacity: 0.08;
      display: block;
      transform: rotate(45deg);
    }
  }
  &--inner {
    max-height: 800px;
    max-height: 100vh;
    margin-bottom:60px;
    overflow-y: scroll;

  }
  &--7,
  &--8{
    .card-section:before {
      font-size: 105px;
      top:40%;
      left: 20%;
    }
    .pdfSimulation--inner {
      overflow: hidden;
    }
  }
}

// step-five

.step-five {
  .form-check-input {
    border: 0;
  }
  .btn-secondary {
    margin-top: 50px;
  }
}

.step-confirmed {
  .btn-secondary {
    margin-top: 20px;
  }
}
