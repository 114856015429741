.contact {
  .section__title {
    margin-bottom: 80px;
  }
}

.contact-form {
  .card{
    h3{
      color: $primary;
      margin-bottom: 60px;
    }
  }
  .col-xl-5 {
    @include up(1400) {
      width: calc(41.66666667% + var(--bs-gutter-x) * 0.5);
    }
  }
  .panel {
    border-radius: 0;
    position: relative;
    h3{
      margin-top: 60px;
    }
    @include up(1400) {
      padding-left: calc(90px);
      padding-top: 80px;
      padding-bottom: 130px;
    }
    @include up(1600) {
      padding-left: calc(90px + var(--bs-gutter-x) * 1.5);
    }

    .contact-map {
      opacity: 0.5;
      bottom: 20px;
      position: absolute;
      right: 48px;
      @include up(1200) {
        bottom: 150px;
      }
      @include up(1800) {
        opacity: 1;
      }
    }
  }
  @include up(1400) {
    .card {
      border-radius: 0;
      margin-left: 36px;
      margin-right: 36px;
    }
  }
}
.contact-form__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @include up(1200) {
    flex-direction: row;
  }

  p {
    margin-bottom: 0;
  }
}

.container-contact {
  @include up(1200) {
    padding-left: 70px;
    padding-right: 70px;
  }
}
