.news {
  .section__title {
    margin-bottom: 10px;
  }
}

.news-intro {
  margin-bottom: 40px;
  @include down(1199) {
    margin-top: 20px;
  }
  @include up(1200) {
    margin-bottom: 85px;
  }

  p {
    @include up(1200) {
      padding-left: 90px;
    }
  }

  .card {
    margin-bottom: 0;
  }
}

.news-listing {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--bs-gutter-x);
  margin-bottom: 50px;

  @include up(768) {
    gap: 75px var(--bs-gutter-x);
    grid-template-columns: repeat(2, 1fr);
  }
  @include up(1200) {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 150px;
  }
  &.paginated{
    margin-bottom: 60px;
  }
}

.news-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 20px;
  margin-top: 34px;
}

.news-cat {
  color: white;
  background: var(--primary);
  opacity: 0.5;
  padding: 10px 20px;
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--font-title);
  transition: opacity 250ms;

  &:hover,
  .active {
    color: white;
    opacity: 1;
  }
}

/* -----------------------------------------------------
    Detail
   ----------------------------------------------------- */

.news-detail {
  position: relative;
  .bg-icon {
    &:first-of-type {
      top: 400px;
      width: 600px;
      height: 600px;
      right: -100px;
      z-index: -1;
      --bg-icon-rotate: -25deg;
    }
    &:last-of-type {
      bottom: -316px;
      width: 600px;
      height: 600px;
      left: -112px;
      z-index: -1;
      --bg-icon-rotate: -25deg;
    }
  }
  h1 {
    margin-top: 82px;
    margin-bottom: 0.446em;
    text-transform: uppercase;
    @include up(1200) {
    }
  }

  .question-card {
    @include up(1200) {
      margin-bottom: 150px;
    }
  }
}

.news-share {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 20px;
  @include up(1200) {
    margin-bottom: 63px;
  }
  &__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    gap: 15px;
    color: var(--color-title);
    text-decoration: none;
    transition: color 250ms;
    svg path {
      fill: currentColor;
      transition: fill 250ms;
    }
    &:hover {
      color: var(--primary);
    }
  }
}

.news-content {
  @include up(1200) {
    margin-bottom: 120px;
  }
  img {
    margin-bottom: 20px;
    @include up(1200) {
      margin-bottom: 66px;
    }
  }

  h4 {
    margin-bottom: 1.154em;
  }

  h5 {
    margin-bottom: 0.5em;
    color: $primary;
  }
}
