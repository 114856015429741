.label {
  --label-color: #5099AB;
  background: var(--label-color);
  /*&--avec-surete {
    --label-color: #42b840;
  }
  &--winwinlening {
    --label-color: #00ae65;
  }
  &--pret-coup-de-pouce {
    --label-color: #6db29b;
  }
  &--plan-startup {
    --label-color: #89b049;
  }
  &--pret-proxi,
  &--ladin {
    --label-color: #458344;
  }
  &--sold,
  &--equity {
    --label-color: #0031ae;
  }*/
  &--coinvest {
    --label-color: #5099AB;
  }
}
