.alert{
  font-size: 16px;
  text-align: center;
  ul li{
    text-align: left !important;
  }
  p{
    margin: 0;
  }
}

.fineTooltip{
  margin-left:10px;
  display: flex;
  gap: 10px;
  top: -2px;
  svg{
    opacity: .7;
    fill: $yellow;
    stroke: rgb(43, 52, 71);
    cursor:pointer;
  }
  position: relative;
  span{
    position: absolute;
    display: none;
    background: white;
    bottom: calc(100% + 10px);
    left: 0;
    border-radius: 5px;
    //border: 1px red solid;
    z-index: 9999;
    min-width: 240px;
    overflow: auto;
    padding: 10px;
    box-shadow: 0px 10px 40px rgba(209, 220, 242, 0.8);
  }
  &--auto{
    span{
      min-width: 0;
    }
  }
  &:hover{
    
    svg{
      transition: all .3s;
      opacity: 1;
    }
    span{
      display: block;
    }
  }
  .table-invest &{
    display: inline-block;
  }
}

.tooltipC{
  margin-top: -30px;
  margin-bottom: 5px;
  float: right;
  cursor: pointer;
  background: #eeb72d;
  opacity: 0.7;
  width: 24px;
  height: 24px;
  display: flex !important;

  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: 250ms opacity;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
  font-family: var(--font-title);
  &--select{
    margin-right: 40px;
  }
  &--checkbox{
    margin-top: 5px;
  }
  &--text{
    float: none;
    position: relative !important;
    display: inline-flex !important;
    margin: 0px 0 0 20px !important;
    top: 0 !important;
  }
  &--text--small{
    margin: 0px 0 0 5px !important;
    width: 18px;
    height: 18px;
  }
  &--text--big{
    width: 36px;
    height: 36px;
    margin: -5px 20px 0 0 !important;
    opacity: 1;
    top: -5px !important;
    cursor: default;
    font-size: 20px;
    font-weight: 700;
  }
  &:hover {
    opacity: 1;
  }
}

.notification--dot{
  @extend .tooltipC;
  margin: 0;
  position: relative;
  opacity: 1;
  font-style: normal;
  width: 22px;
  height: 22px;
  font-size: 14px;
  &:after{
    content: '!';
  }
  &--valid{
    background: #539f84;
    &:after{
      content: '✓';
    }
  }
  &--pending{
    background: rgba($primary,0.2);
    &:after{
      content: '🕐';
    }
  }
}

.notyf{
  &__toast{
    max-width: 90vw !important;
    width: 520px;
  }
  &.__wrapper{
    padding-top: 24px;
    padding-bottom: 24px;
  }
  &__message{
    font-size: 18px;
    margin-left: 15px;
    p{
      margin: 0 0 10px;
    }
  }
  &__ripple{
    width: 200% !important;
    height: 300% !important;
  }
  &__toast--success{
    .notyf__ripple{
      background: #539f84 !important;
    }
  }
}