@at-root {
  :root {
    --bs-gutter-x: 40px;
  }
}

html {
  position: relative;
}

img {
  // width: 100%;
  max-width: 100%;
  height: auto;
}

.sr-only {
  position: absolute;

  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  border: 0;
}

@media (min-width: 1024px) {
  .no-padding {
    padding: 0;
  }
}

.d-flex {
  display: flex;
  gap: var(--bs-gutter-x);
}

.d-none {
  display: none !important;
}

.d-lg-none {
  @include up(1200) {
    display: none !important;
  }
}
.d-lg-block {
  @include up(1200) {
    display: block !important;
  }
}

.mb-5 {
  margin-bottom: 1.875em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.text-lg-right {
  @include up(1200) {
    text-align: right;
  }
}
.text-center {
  text-align: center;
}
.flex-column {
  flex-direction: column;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

@media (min-width: 1400px) {
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
}

// :root {
//   --bs-gutter-x: 40px;
// }
.container,
.row {
  // --bs-gutter-x: 40px;
  // --bs-gutter-y: 0;
}

.container {
  @include up(1580) {
    max-width: 1400px;
  }
  @include up(1680) {
    max-width: 1580px;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 40px;
  --bs-gutter-y: 0;
}

body:not(.no-grid)::after {
  opacity: 0.5;
}

.offset-xl-05 {
  @include up(1200) {
    margin-left: 4.1667%;
  }
}

.offset-gutter {
  @include up(1400) {
    margin-right: calc(var(--bs-gutter-x) * -1);
  }
}
.noffset-gutter {
  @include up(1400) {
    margin-left: calc(var(--bs-gutter-x) * -1);
  }
}
.offset-gutter-half {
  @include up(1400) {
    margin-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.noffset-gutter-half {
  @include up(1400) {
    margin-left: calc(var(--bs-gutter-x) * -0.5);
  }
}
// .offset-gutter {
//   --bs-gutter-x: 40px;
//   margin-left: var(--bs-gutter-x);
// }
// .noffset-gutter {
//   --bs-gutter-x: 40px;
//   margin-left: calc(var(--bs-gutter-x) * -1);
// }

.hidden {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .collapse.dont-collapse-lg {
    height: auto !important;
    visibility: visible;
  }
}

.transition-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background-color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: 0.15s ease-out;
  display: none;
}

.transition-layer.is-active {
  display: flex;
  pointer-events: all;
  opacity: 1;
  transition: 0.15s ease-in;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba($color: #ffffff, $alpha: .6);
  top: 0;
  right: 0;
}

.col-xl-10-gutter {
  @include up(1400) { 
    width: calc(83.33333333% + var(--bs-gutter-x));
    padding: 0;
  }
}

.order-xl-0 {
  @include up(1200) {
    order: 0;
  }
}
.order-xl-1 {
  @include up(1200) {
    order: 1;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.g-max{
  --bs-gutter-x: 6rem;
}

.my-100{
  margin: 40px 0  !important;
  @include up(1200) {
    margin: 80px 0 !important;
  }
}

.smallP,
.smallP p{
  font-size: 18px;
  font-weight: 400;
}

.smallLine{
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}