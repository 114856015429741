.home .nav-wrapper {
  @include up(1200) {
    background: transparent;
  }
}

.home .header {
  @include up(1200) {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 0;
    top: 40px;
  }
}

.home .section-hero {
  position: relative;
  padding-top: 0;
  margin-bottom: 40px;

  @include up(1200) {
    padding: 40px;
    margin-bottom: 20px;
  }

  .slide-content {
    font-family: var(--font-title);
    position: absolute;
    z-index: 2;
    bottom: 68px;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    left: 0;
    @include up(1440) {
      bottom: 160px;
      left: initial;
      padding: 0;
    }
  }
}

.slide-bg {
  position: relative;

  img {
    width: 100%;
    object-fit: cover;
    height: 75vh;
    min-height: 478px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1;
  }

  &::before {
    background: radial-gradient(
        91.38% 91.38% at 95.07% 13.57%,
        #ffffff 0%,
        #289db4 100%,
        #289db4 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: darken;
  }
  &::after {
    background: radial-gradient(
        82.36% 82.36% at 0.93% 96.09%,
        #399f7d 50%,
        rgba(40, 157, 180, 0.44) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
    opacity: 0.5;
  }
}

.swiper-hero .swiper-slide {
  height: 76.5vh;
  .slide-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    /*max-height: -webkit-fill-available;
    min-height: 100%;*/
    @include down(1199) {
      position: absolute;
      max-width: 100%;
      overflow: hidden;
    }
    video {
      object-fit: cover;
      min-width: 100%;
      height: 77vh;
      @include down(1199) {
        min-width: auto;
      }
    }
  }
}

.swiper-slide[aria-label="1 / 3"] .slide__text {
  max-width: 42ch;
}

.slide {
  &__title {
    font-weight: 700;
    font-size: 15vw;
    line-height: 1;
    margin-bottom: 20px;
    left: 15px;
    display: block;
    opacity: 0.4;
    -webkit-text-stroke: 2px white;
    -webkit-text-fill-color: transparent;
    // display: none;

    @include up(480) {
      margin-bottom: 0;
      position: absolute;
      top: calc(-1 * (15vw / 1.45));
    }

    @include up(768) {
      font-size: 124px;
      top: -88px;
      left: 5px;
    }

    @include up(1200) {
      font-size: 230px;
      top: -175px;
      left: 0px;
    }

    @include up(1440) {
      left: -60px;
    }
  }
  &__text {
    color: white;
    font-weight: 700;
    font-size: var(--font-size-h4);
    max-width: 45ch;
    line-height: 1.1;
    margin-bottom: 30px;
    @include up(1200) {
      margin-bottom: 40px;
    }
    @include up(1440) {
      margin-bottom: 70px;
    }
  }
}

.banner{
  margin-top: -100px;
  margin-bottom: 80px;
  z-index: 3;
  position: relative;
  @include down(1440) {
    margin-top: -80px;
  }
  
  .loan{
    padding: 50px 80px;
    &:hover{
      transform: none;
    }
  }
  @include down(990) {
    margin-top: 0px;
    .loan{
      padding: 50px 30px;
    }
  }
}

/* -----------------------------------------------------
    Projects
   ----------------------------------------------------- */

.home .section-projects {
  position: relative;
  margin-bottom: 60px;

  h2 {
    max-width: 20ch;
  }

  p {
    max-width: 68ch;
  }

  .bg-icon--projects-one {
    --bg-icon-rotate-start: -15deg;
    width: 500px;
    height: 500px;
    right: -81px;
    top: -147px;
    transform: rotate(var(--bg-icon-rotate-start));
    z-index: 2;
  }
  .bg-icon--projects-two {
    --bg-icon-rotate-start: 0deg;
    left: 50%;
    top: 230px;
    transform: rotate(var(--bg-icon-rotate-start));
    z-index: -1;
  }
  .bg-icon--projects-three {
    --bg-icon-rotate-start: -180deg;
    width: 500px;
    height: 500px;
    left: -139px;
    bottom: -20px;
    z-index: -1;
    transform: rotate(var(--bg-icon-rotate-start));
    // transform: scaleX(-1);
  }

  @include up(1200) {
    margin-bottom: 90px;
  }
}

.home-projects-listing {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin-bottom: 30px;

  @include up(1200) {
    grid-template-columns: repeat(3, 1fr);
    gap: var(--bs-gutter-x);
    margin-bottom: 100px;
  }
}

.home-project-item {
  position: relative;
  &__link {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: block;
  }

  &__content {
    position: absolute;
    z-index: 1;
    bottom: 0;
    background: linear-gradient(90deg, #fff 0%, #fff 100%);
    background-origin: border-box;
    width: 63%;
    // padding: 2px 20px 2px 20px;
    padding: 11px 20px 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: 300ms all;

    @include down(768) {
      max-width: 230px;
    }

    @include up(1200) {
      width: 70%;
      padding: 2px 20px 2px 35px;
    }
  }

  &__icon {
    width: 57px;
    color: var(--color-title);
    stroke: var(--color-title);
    //transition: 500ms all;
    flex-shrink: 0;

    @include up(1200) {
      width: 100px;
    }
    &:hover {
      stroke: #fff;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.22;
    max-width: 13ch;
    display: block;
    color: var(--color-title);
    //transition: 500ms color;
    word-break: break-word;
    @include up(1200) {
      font-size: 22px;
    }
  }

  &__image img {
    width: 100%;
    object-fit: cover;
    @include down(1199) {
      aspect-ratio: 2.25 / 1;
    }
  }

  &:hover & {
    &__content {
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(90deg, #539f84 0%, #289db4 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
    &__title,
    &__icon {
      color: white;
    }

    &__icon {
      fill: none;
      stroke: white;
    }
  }
}

/* -----------------------------------------------------
    Numbers
   ----------------------------------------------------- */

.home .section-numbers {
  margin-bottom: 60px;
  @include up(1200) {
    margin-bottom: 90px;
  }

  h2 {
    max-width: 20ch;
  }

  p {
    max-width: 57ch;
    margin-bottom: 30px;
  }

  .section__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logos {
    display: flex;
    margin-left: auto;
    gap: 35px;

    @include up(1200) {
      gap: 40px;
      margin: 0;
    }
  }
}

.numbers-wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  @include down(1199) {
    position: relative;
    padding-bottom: 110px;
    .btn-main {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
  @include up(1200) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.numbers {
  position: relative;
  padding: 0px 17px;
  margin: 0 auto;
  width: fit-content;
  @include up(768) {
    padding: 0;
  }
  @include up(1200) {
    padding: 0;
    top: -40px;
    margin-right: 40px;
  }
}

.number {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 10px 40px #d1dcf2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: fit-content;
  text-align: center;
  padding: 2.5px;
  color: var(--color-title);
  font-weight: 700;
  font-family: var(--font-title);
  //gap: 2.5px;

  &__icon {
    display: none;
    @include up(768) {
      display: block;
    }
  }

  &__prefix,
  &__suffix {
    font-size: 16px;
    line-height: 1.2;
    @include down(1199) {
      max-width: 9ch;
      .number--belgium &{
        max-width: 10ch;
      }
    }
    @include up(1440) {
      font-size: 20px;
    }
  }

  &__value {
    font-size: 36px;
    line-height: 1;

    @include up(1440) {
      font-size: 56px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    width: 33px;
    @include up(1440) {
      width: auto;
    }
  }

  &--raised {
    left: 36px;
    top: -25px;
    padding: 18px 18px;
    border-radius: 50%;
    @include up(768) {
      left: 30px;
      top: 0;
    }
    @include up(1440) {
      left: -85px;
      top: 40px;
      padding: 43px 29px;
    }
  }
  &--members {
    right: 0px;
    top: 86px;

    padding: 15px 15px;

    @include up(1440) {
      right: -54px;
      top: 142px;

      padding: 24px 30px;
    }
  }
  &--year .number__value,
  &--belgium .number__value {
    font-size: 24px;

    @include up(1440) {
      font-size: 36px;
    }
  }

  &--year {
    left: 19px;
    top: 200px;
    padding: 9px 31px;
    @include up(768) {
      left: -23px;
      top: 264px;
    }
    @include up(1440) {
      left: -23px;
      top: 264px;
      padding: 19px 38px;
    }
  }

  &--belgium {
    right: 28px;
    bottom: -30px;
    padding: 11px 11.6px;
    @include up(768) {
      right: 78px;
      bottom: 0;
    }
    @include up(1440) {
      right: 78px;
      bottom: -36px;
      padding: 31px 25.6px;
    }
  }
}
/* -----------------------------------------------------
    Crowdlending
   ----------------------------------------------------- */
.section-crowdlending {
  p {
    margin-bottom: 30px;
    @include up(1200) {
      max-width: 85ch;
      margin-bottom: 75px;
    }
  }

  .section__footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    margin-bottom: 34px;
    @include up(1200) {
      flex-direction: row;
      gap: 40px;
      margin-bottom: 75px;
    }
  }
}

.crowdlending-intro-home {
  margin-bottom: 30px;
  padding: 20px 0 34px 0;
  box-shadow: 0px 10px 40px #d1dcf2;
  @include up(1200) {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
}
.crowdlending-advantages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  margin-bottom: 60px;

  @include down(576) {
    margin-left: -20px;
    margin-right: -20px;
  }

  @include up(1200) {
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    margin-bottom: 105px;
  }

  @include up(1580) {
    margin-bottom: 128px;
  }
}

.crowdlending-advantage__item {
  padding: 20px 20px 20px 20px;
  margin-bottom: 0;

  ul {
    padding-left: 20px;
    max-width: 50ch;
  }

  li {
    margin-bottom: 10px;
    @include up(1200) {
      margin-bottom: 26px;
    }
  }

  @include up(1200) {
    width: calc(50% - 8.3333% + 20px);
    padding: 40px 40px 40px 40px;
  }
  @include up(1580) {
    padding: 33px 70px 48px 90px;
    min-height: 560px;
  }
}

/* -----------------------------------------------------
    Stories
   ----------------------------------------------------- */

.section-stories {
  margin-bottom: 60px;
  .container {
    position: relative;
    @include down(1200) {
      padding: 0;
    }
  }
  .section__title {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    top: 0;
    margin-top: 0;
    margin-bottom: 10px;
    @include up(1200) {
      position: absolute;
      bottom: -163px;
      left: -36px;
      top: initial;
      margin-bottom: 0;
    }
  }
  @include up(1200) {
    margin-bottom: 105px;
  }
  @include up(1580) {
    margin-bottom: 120px;
  }
}

.bg-icon--stories-one {
  --bg-icon-rotate-start: -30deg;
  width: 550px;
  height: 550px;
  right: -120px;
  bottom: -410px;
  transform: rotate(var(--bg-icon-rotate-start));
  z-index: -1;
}

.bg-icon--stories-two {
  --bg-icon-rotate-start: -184deg;
  width: 600px;
  height: 600px;

  left: 14px;
  top: 45px;
  transform: rotate(var(--bg-icon-rotate-start));
  z-index: -1;
}

.stories-first {
  position: relative;
  margin-bottom: 60px;
  @include up(1200) {
    margin-bottom: 195px;
  }
  @include up(1580) {
    margin-bottom: 330px;
  }
}

.stories-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 35px;

  @include up(1200) {
    flex-direction: row;
  }
}
.stories-videos {
  max-width: 100%;
  @include up(1200) {
    max-width: 880px;
    width: 57.8947%;
  }
}

.stories-content {
  @include down(1200) {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  @include up(1200) {
    width: calc((100% - var(--bs-gutter-x) * 2) * 0.333333);
  }
}

.stories-second {
  position: relative;
}

.stories-testimonies {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonies-wrapper {
  display: flex;
  gap: var(--bs-gutter-x);
  justify-content: space-between;
  flex-direction: column;
  @include up(1200) {
    flex-direction: row;
    align-items: center;
  }
}

#profile-link{
  text-align: center;
  .tp-widget-logo{
    max-width: none !important;
    width: 80% !important;
  }
}

.trustpilot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  gap: 8px;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 54px;


  
  @include up(1200) {
    box-shadow: 0px 10px 40px #d1dcf2;
    order: 2;
    margin-bottom: 0;

    width: fit-content;
    margin: 0 auto;
    padding-bottom: 5px;
  }

  span {
    display: inline-block;
    margin-top: 8px;
    font-size: 18px;
    line-height: 1.26;
    color: #000;
  }
  #tp-widget-logo{
    width: 100% !important;

  }
}

.stories-testimonies {
  box-shadow: 0px 10px 40px #d1dcf2;
  margin-bottom: 60px;
  @include up(1200) {
    margin-bottom: 0;
    box-shadow: none;
    width: calc(
      (100% - var(--bs-gutter-x) * 2) * 0.416666 + var(--bs-gutter-x)
    );
  }
}

.testimony-item {
  img {
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    margin-bottom: 40px;
    max-width: 135px;
    @include up(1200) {
      width: auto;
    }
  }
  h4,
  p {
    text-align: center;
    font-size: 20px;
    display: block;
    margin: 0;
  }
}

.stories-community {
  padding: 40px 20px;
  margin-bottom: 0;
  @include up(1200) {
    width: calc(50% - var(--bs-gutter-x) * 0.5);
    padding: 60px 60px 60px 60px;
  }

  @include up(1580) {
    padding: 92px 130px 104px 89px;
  }

  p {
    @include up(1200) {
      max-width: 46ch;
    }
  }
}

.swiper-testimonies {
  margin-bottom: 47px;
  @include up(1200) {
    margin-bottom: 73px;
  }
}

.swiper-news,
.swiper-partners,
.swiper-testimonies {
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-horizontal-gap: 6px;

  .swiper-pagination-bullet {
    background: #ededee;
    border: 0;
  }
  .swiper-pagination-bullet-active {
    background: #539f84;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    position: relative;
    inset: initial;
    width: 100%;
    margin-top: 70px;
    @include up(1200) {
      margin-top: 20px;
    }
  }
}

.newsletter .text-right {
  margin-top: 20px;
  @include up(1200) {
    margin-top: 42px;
  }
}

.swiper-partners, 

/* -----------------------------------------------------
News
----------------------------------------------------- */
.section-news {
  margin-bottom: 60px;

  .section__title {
    top: 0;
    margin-top: 0;
  }

  .swiper {
    margin-bottom: 54px;
    @include up(1200) {
      margin-bottom: 65px;
    }
  }

  .text-center {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

.news-item {
  position: relative;
  transition: 250ms background-color;

  &:hover {
    background-color: #ededee;
  }

  &__link {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: block;
  }

  &__category {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 700;

    line-height: 1.2;
    display: inline-block;

    text-align: right;

    color: #ffffff;

    background: linear-gradient(90deg, #289db4 0%, #539f84 100%);
    padding: 9px 21px;

    @include up(1200) {
      padding: 18px 39px;
    }
  }

  &__content {
    padding: 15px 25px;
    p {
      margin-bottom: 0;
    }
  }

  img {
    aspect-ratio: 1.35 / 1;
    // aspect-ratio: 480 / 360;
    width: 100%;
    object-fit: cover;
  }
}

/* -----------------------------------------------------
    Partners
   ----------------------------------------------------- */
.section-partners {
  margin-bottom: 40px;

  @include up(1200) {
    margin-bottom: 85px;
  }
  @include up(1580) {
    margin-bottom: 130px;
  }

  h2 {
    text-align: center;
    margin-bottom: 40px;
    @include up(1200) {
      margin-bottom: 70px;
    }
  }
}
