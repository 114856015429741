.page-partners {
  .section__title {
    margin-bottom: 10px;
  }
  h3 {
    @include up(1200) {
      max-width: 50ch;
    }
  }
}

.partners-listing {
  display: flex;

  margin-top: 20px;
  margin-bottom: 20px;

  @include down(767) {
    overflow: auto;
  }

  @include up(768) {
    display: grid;
    gap: var(--bs-gutter-x);
    grid-template-columns: repeat(2, 1fr);
  }

  @include up(1200) {
    display: grid;
    gap: 90px var(--bs-gutter-x);
    margin-top: 100px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.partner {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 30px #eef1f7;
  background: white;
  padding: 20px;
  @include down(767) {
    min-width: 305px;
  }
  @include up(1200) {
    align-items: center;
    text-align: center;
    padding: 40px;
  }
  img {
    margin-bottom: 20px;
    @include up(1200) {
      margin-bottom: 40px;
    }
  }

  &__name {
    margin-bottom: 0.769em;
    text-transform: uppercase;
  }

  &__desc {
    line-height: 1.7;
  }
}

.section-partners-main,
.section-partners-organisations {
  margin-bottom: 90px;
  @include up(1200) {
    margin-bottom: 120px;
  }
}

.section-partners-join {
  .section__title {
    z-index: 2;
  }
  .col-xl-9 {
    @include up(1440) {
      width: calc(75% + var(--bs-gutter-x));
      // padding: 0;
    }
  }

  h3 {
    margin-bottom: 1.389em;
  }

  .card {
    color: var(--color-title);
    border: none;
    @include up(1440) {
      padding-top: 110px;
      padding-bottom: 110px;
    }

    p {
      @include up(1400) {
        max-width: 60ch;
      }
    }
  }

  .join {
    position: relative;
    width: 100%;
  }

  .panel {
    padding: 50px 70px;
    z-index: 2;

    right: 0;
    bottom: 53px;

    @include up(1400) {
      position: absolute;
    }
  }
}
