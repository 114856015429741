.profile-form {
  margin-bottom: 75px;
  // margin-top: ;
  position: relative;
  .bg-icon {
    right: -60px;
    top: calc(50% - 300px);
    width: 550px;
    height: 550px;
    --bg-icon-rotate: -25deg;
    z-index: -1;
  }

  @include up(1200) {
    margin-bottom: 150px;
  }
}

.card-profile-form {
  margin-top: 13px;
  padding: 45px 20px;

  @include up(1200) {
    margin: 0 0px;
    margin-top: 26px;
    padding: 90px;
  }

  .text-help,
  .text-help p {
    margin-bottom: 0;
  }
}

.profile-questions {
  @include up(1200) {
    margin-bottom: 140px;
  }
  .section__title {
    z-index: 1;
    margin-bottom: 15px;
  }
  h3 {
    margin-bottom: 1.111em;
  }

  .btn-main {
    margin-top: 12px;
  }

  .panel--large {
    padding-top: 78px;
    padding-bottom: 65px;

    padding-left: 20px;
    padding-right: 20px;

    @include down(576) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

.profile-identity {
  position: relative;
  .bg-icon {
    left: -20px;
    bottom: -360px;
    width: 550px;
    height: 550px;
    --bg-icon-rotate: -25deg;
    z-index: -1;
  }
  @include up(1200) {
    margin-bottom: 140px;
  }
}
