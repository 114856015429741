.pagination{
    display: flex;
    justify-self: center;
    margin: 0 auto 60px;

    --bs-pagination-padding-x: 1rem;
    --bs-pagination-padding-y: 0.6rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-color: #539f84;
    --bs-pagination-bg: #fff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #dee2e6;
    --bs-pagination-border-radius: 0;
    --bs-pagination-hover-color: #343a40;
    --bs-pagination-hover-bg: #e9ecef;
    --bs-pagination-hover-border-color: #dee2e6;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #e9ecef;
    --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(59, 125, 221, 0.25);
    --bs-pagination-active-color: #fff;
    --bs-pagination-active-bg: #539f84;
    --bs-pagination-active-border-color: #539f84;
    --bs-pagination-disabled-color: #6c757d;
    --bs-pagination-disabled-bg: #fff;
    --bs-pagination-disabled-border-color: #dee2e6;
    
    li{
     
      &.active>.page-link, 
      .page-link.active{

      }
    }
}

