@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin up($size) {
  $size: strip-unit($size);
  @media (min-width: $size * 1px) {
    @content;
  }
}
@mixin down($size) {
  $size: strip-unit($size);
  @media (max-width: $size * 1px) {
    @content;
  }
}

%box-shadow {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 6px 12px 0 rgba(0, 0, 0, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
}
