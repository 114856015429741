.header {
  @include up(1200) {
    padding: 40px 40px 0;
  }
}

.nav-wrapper {
  background: linear-gradient(90deg, #539f84 0%, #289db4 57.29%);
  padding: 21px 20px 16px 20px;

  .container {
    padding: 0;
  }

  @include up(480) {
    padding: 21px 45px 16px 45px;
  }

  @include up(1200) {
    padding: 45px;
    .container {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }

  @include up(1580) {
    .container {
      max-width: 1440px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include up(1780) {
    .container {
      max-width: 1610px;
    }
  }
}

.navbar {
  padding: 0;
}

.navbar-collapse {
  order: 1;
  position: relative;
  margin-top: 40px;
  @include up(480) {
    left: -15px;
  }
  @include up(1200) {
    margin-top: 0;
    left: 0;
    order: initial;
  }
}

.navbar-nav {
  @include up(1200) {
    margin-left: auto;
    gap: 10px;
    align-items: center;
  }
  @include up(1460) {
    gap: 20px;
  }
}

.navbar-brand {
  margin-right: 0;
  padding: 0;
  flex-shrink: 0;
  .logo-full {
    display: none;
    width: 130px;
    @include up(480) {
      width: 170px;
    }
    @include up(1400) {
      display: block;
      width: 250px;
    }

    @include up(1920) {
      width: 366px;
    }
  }

  .logo-mobile {
    width: 43px;
    height: 42px;
    @include up(1400) {
      display: none;
    }
  }
}

.header .nav-item {
  .nav-link {
    display: block;
    @include up(1200) {
      &::before {
        content: "";
        bottom: 8px;
        left: 0.5rem;
        position: absolute;
        opacity: 0;
        background: white;
        height: 3px;
        width: calc(100% - 1rem);

        transition: 250ms opacity;
      }
    }
  }
  .dropdown-toggle {
    &::before {
      bottom: 15px;
      height: 3px;
      width: calc(100% - 16px - 0.75em - 1rem);
    }
  }

  &:hover {
    .nav-link {
      &::before {
        opacity: 1;
      }
    }
  }
}

.nav-link {
  font-size: 20px;

  font-weight: 700;

  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;

  @include up(1200) {
    font-size: 16px;
  }
  @include up(1460) {
    font-size: 18px;
  }
  &:hover {
    color: white;
  }
}

.header .nav-link {
  text-transform: uppercase;
  font-family: var(--font-title);
}

.header .dropdown:hover {
  @include up(1200) {
    .dropdown-menu {
      display: block;
    }
  }
}

.navbar-nav .dropdown-toggle {
  cursor: pointer;
  @include down(1199) {
    &::after {
      width: 20px;
      height: 10px;
      transition: transform 250ms;
      position: absolute;
      top: 16px;
      right: 0;
    }

    &.show::after {
      transform: rotate(180deg);
    }
  }
}
.header .dropdown-toggle {
  @include up(1200) {
    //padding-bottom: 10px;
    margin-top: 8px;
    padding-bottom: 16px;
  }
}

.header .dropdown-menu {
  @include up(1200) {
    padding: 22px 25px;
    background: white;
    border: 1px solid #ededee;
    border-radius: 10px;
    left: -24px;
    //margin-top: calc(100% + 5px);
    li + li {
      margin-top: 10px;
    }
  }
}
.header .dropdown-item {
  font-family: var(--font-title);
  @include up(1200) {
    font-size: 20px;
    background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;

    &::after {
      content: "";
      bottom: 0;
      position: absolute;
      opacity: 0;
      background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
      height: 3px;
      width: 100%;
      display: block;
      transition: 250ms opacity;
    }
  }
}

.navbar-toggler {
  padding: 0;
  width: 25px;
  height: 18px;
  background: transparent;
  border: 0;
  box-shadow: none;
  z-index: 3;
  border-radius: 0;

  &:focus {
    box-shadow: none;
  }
}

.navbar-toggler .icon-bar {
  background-color: white;
  transform: rotate(0deg) translate(0px, 0px);
  transition: ease all 0.2s;
  display: block;
  width: 25px;
  height: 2px;
  margin: 0 auto;
}

.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 5px;
}

.navbar-toggler[aria-expanded="true"] .icon-bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
  transition: ease all 0.2s;
}

.navbar-toggler[aria-expanded="true"] .icon-bar:nth-child(2) {
  opacity: 0;
  transition: ease all 0.2s;
}

.navbar-toggler[aria-expanded="true"] .icon-bar:nth-child(3) {
  transform: rotate(-45deg) translate(4px, -5px);
  transition: ease all 0.2s;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 35px;
  @include up(1200) {
    margin-left: 30px;
  }
  .lang-switcher {
    display: none;
    @include up(1200) {
      display: block;
      margin-top: 9px;
      .dropdown-menu {
        padding: 22px 25px;
      }
      .dropdown-item {
        text-transform: uppercase;
        font-size: 18px;
      }
    }
    .dropdown-menu {
      @include down(1199) {
        padding: 0;
        li + li {
          margin-top: 0;
        }
      }
    }
  }
}

.nav-open {
  .nav-right .lang-switcher {
    display: block;
  }
  .nav-right .btn-login {
    display: none;
  }

  .logo-mobile {
    display: none;
  }
  .logo-full {
    display: block;
  }
}

.socials {
  position: fixed;
  top: 50vh;
  transform: translateY(-50%);
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  gap: 30px;
  margin: auto;
  padding: 10px;
  display: none;
  z-index: 2;

  a svg path {
    transition: 250ms ease-in;
  }
  a:hover {
    svg path {
      fill: #399f7d;
    }
  }

  @include up(1200) {
    display: flex;
  }
}

.account .header {
  padding-bottom: 0;
}

.subHeader {
  background: white;
  padding: 5px 0 5px;

  box-shadow: 0px 10px 30px #eef1f7;
  margin-bottom: 20px;

  @include up(1200) {
    margin: 0 40px;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    > a,  > div > a{
      margin: 10px 0;
      min-height: 50px;
    }

    @include down(1199) {
      gap: 10px;
    }

    @include up(1200) {
      flex-direction: row;
      padding: 0 24px;
    }

    @include up(1440) {
      padding: 0 62.5px;
    }
  }

  .dropdown-menu {
    //display: block !important;
    padding: 22px 25px 12px;
    background: white;
    border: 1px solid #ededee;
    border-radius: 10px;
    left: 0px;
    //margin-top: 20px;
    width: max-content;
    min-width: -webkit-fill-available;
    a{
      color: $black;
      margin-bottom: 18px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 24px */
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }
    
  }
  @include up(1200) {
    .dropdown:hover .dropdown-menu {
      display: block;
    }
  }

  nav a {
    color: var(--color-title);
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    
    &.dropdown-toggle {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 18 10%27%3E%3Cpath stroke=%27%232b3447%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m1 1 8 8 8-8%27/%3E%3C/svg%3E%0A");
      }
    }

    &:hover,
    &.active {
      color: var(--primary);
    }

    &.yellow,
    &.green {
      padding-bottom: 0;
    }
  }

  @include down(1199) {
    nav a {
      min-height: 20px;
    }
    .dropdown{
      width: 100%;
      a{
        width: 100%;
        justify-content: center;
        min-height: 20px;
      }
    }
  }

}

#completionProcess{
  background: rgba($yellow,0.6);
  text-align: center;
  padding: 30px 0;

  &.pending{
    background: rgba($primary,0.2);
  }
  
  &.pendingLegacy{
    background: rgba(#289db4 ,0.2);
    &--project{
      margin: -40px 40px 0;
      padding-top: 45px;
      padding-bottom: 45px;
      h5 {
        margin-bottom: 0;
      }
    }
  }
  

  h5{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: $black;
  }
  nav{
    display: flex;
    justify-content: center;
    gap:15px;
    @include down(1199) {
      flex-direction: column;
      align-items: center;
    }
    
    a,span{
      display: flex;
      align-items: center;
      gap: 15px;
      @include up(1200) {
        &:after{
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          background-color: $primary;
          border-radius: 50%;
        }
      }
    }
    & > :last-child{
      &:after{
        content: none;
      }
    }
    span{
      font-family: var(--font-title);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
    a{
      
      font-family: var(--font-title);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      text-decoration: none;
      

    }
  }
}

.notification-invest {
  display: flex;
  align-items: center;
  background: var(--primary);
  border-radius: 50%;
  padding: 3px 10.45px;
  margin-right: 10px;
  color: white;
  font-weight: 600;
  aspect-ratio: 1/1;
}

.chart {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circular-chart {
  display: inline-block;
  width: 41px;
  margin-right: 10px;
}

.circle-bg {
  fill: none;
  stroke: #ededee;
  stroke-width: 2;
}

.circle {
  fill: none;
  stroke-width: 2;
  stroke: #eeb72d;
  .complete &{
    stroke: $primary;
  }
}

.percentage {
  fill: #eeb72d;
  font-size: 11px;
  text-anchor: middle;
  font-weight: 600;
  .complete &{
    fill: $primary;
  }
}

@keyframes progress {
  0% {
    stroke: transparent;
    stroke-dasharray: 0 100;
  }
}
