.crowdlending-intro {
  .section__title {
    @include up(1200) {
      // left: calc(-16.6667%);

      margin-bottom: 10px;
    }
  }

  .bg-icon {
    right: -20px;
    top: 300px;
    width: 600px;
    height: 600px;
    transform: rotate(-15deg);
    z-index: -1;
  }
}

.crowdlending-content .row {
  @include up(1200) {
    margin-bottom: 60px;
  }
}

.crowdlending-variants {
  position: relative;
  @include up(1200) {
    max-width: 90ch;
  }

  .bg-icon {
    right: calc(-100% + 340px);
    top: -300px;
    width: 550px;
    height: 550px;
    --bg-icon-rotate-start: -25deg;
    z-index: -1;
  }
}

.crowdlending-categories {
  .panel {
    &__title {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  .panel__img-wrapper {
    left: 0;
    right: 0;
    margin: auto;
  }
  &.crowdlending-categories--financial {
    margin-top: 40px;
    margin-bottom: 45px;
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(3, minmax(305px, 1fr));

    position: relative;

    @include down(991) {
      overflow-x: auto;
      overflow-y: clip;
      padding-bottom: 60px;
    }

    @include up(1200) {
      margin-top: 75px;
      margin-bottom: 150px;
    }

    .bg-icon {
      left: -360px;
      top: 260px;
      width: 550px;
      height: 550px;
      transform: rotate(-20deg);
      z-index: -1;
    }

    // p {
    //   text-align: left;
    // }
  }
  &.crowdlending-categories--non-financial {
    margin-top: 40px;
    margin-bottom: 45px;
    display: grid;

    grid-template-columns: repeat(2, minmax(305px, 1fr));
    gap: var(--bs-gutter-x);
    position: relative;

    @include down(767) {
      overflow-x: auto;
      overflow-y: clip;
      padding-bottom: 60px;
    }

    @include up(1200) {
      margin-top: 75px;
      margin-bottom: 150px;
    }

    .panel.panel--icon-bottom {
      @include up(1200) {
        padding: 73px 125px 117px 125px;
      }
    }

    .bg-icon {
      right: -35px;
      top: 300px;
      width: 550px;
      height: 550px;
      transform: rotate(-20deg);
      z-index: -1;
    }

    p {
      text-align: left;
    }
  }
}

.section-crowdlending-invest {
  position: relative;

  h3 {
    @include up(1200) {
      margin-bottom: 78px;
    }
  }

  .buttons-wrapper {
    margin-top: 80px;
    justify-content: center;
    gap: var(--bs-gutter-x);
  }

  .bg-icon {
    left: -260px;
    top: 260px;
    width: 550px;
    height: 550px;
    transform: rotate(-20deg);
    z-index: -1;
  }
}

.invest-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: var(--bs-gutter-x);
  margin-top: 44px;
  @include up(1200) {
    margin-top: 88px;
  }
}

.invest-step__item {
  padding: 20px;

  position: relative;

  @include down(991) {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &-nb {
    font-family: var(--font-title);
    font-weight: 700;
    font-size: 34px;
    line-height: 1;
    color: #539f84;
    position: absolute;
    top: 0;
    left: 0;
    @include up(768) {
      font-size: var(--font-size-h3);
      left: 60px;
    }
  }
  &-image {
    background: linear-gradient(90deg, #289db4 0%, #539f84 100%);
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: grid;
    place-items: center;
    flex-shrink: 0;

    @include up(768) {
      margin: 0 auto;
      margin-bottom: 50px;
    }
  }
  &-title {
    font-family: var(--font-title);
    font-weight: 700;
    line-height: 1;
    width: 20ch;
  }

  @include up(768) {
    text-align: center;
  }
}

.section-crowdlending-agreement {
  margin-bottom: 30px;
  @include up(1200) {
    margin-bottom: 120px;
  }
  .section__title {
    z-index: 1;
    margin-top: 60px;
    margin-bottom: 20px;
    @include up(1200) {
      margin-top: 0;
      left: calc(-16.6667%);
    }
  }

  .panel {
    .col-xl-5 {
      @include up(1200) {
        width: calc(41.66666667% + (var(--bs-gutter-x) / 2));
        margin-left: calc(8.33333% - var(--bs-gutter-x));
      }
    }

    .col-xl-6 {
      @include up(1200) {
        margin-left: auto;
      }
    }
  }
}

.section-crowdlending-risks {
  margin-bottom: 30px;
  @include up(1200) {
    margin-bottom: 120px;
  }
  .section__title {
    z-index: 1;
    @include up(1200) {
      margin-bottom: 10px;
    }
  }

  .col-xl-5 {
    @include up(1200) {
      width: calc(41.66666667% - 20px);

      margin-left: 20px;
    }
    h2 {
      margin-top: 20px;
    }

    p:nth-of-type(3) {
      max-width: 50ch;
    }
  }
}

.crowdlending-more__cards {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 50px 0 50px 0;

  @include up(1200) {
    margin: 95px 65px 130px 65px;
    flex-direction: row;
  }
}

.card-more {
  display: flex;
  background: #ffffff;

  box-shadow: 0px 10px 30px #eef1f7;
  border-radius: 10px;
  gap: 10px;
  align-items: center;
  padding: 24px 10px 24px 10px;
  flex-basis: 33.3333%;

  @include up(1200) {
    gap: 20px;
    padding: 24px 18px 24px 18px;
  }

  svg {
    flex-shrink: 0;
    width: 98px;
    height: 98px;
  }

  p {
    margin-bottom: 0;
  }
}

.section-crowdlending-more {
  position: relative;
  .bg-icon {
    right: -260px;
    top: -280px;
    width: 550px;
    height: 550px;
    transform: rotate(-20deg);
    z-index: -1;
  }
}
