.stories-listing {
  @include down(576) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.story-item {
  background: white;
  box-shadow: 0px 10px 40px #d1dcf2;
  border-radius: 10px;
  display: grid;
  margin-bottom: 90px;

  padding: 74px 20px 124px 20px;

  position: relative;

  @include down(1199) {
    display: flex;
    flex-direction: column;
    .story-item__content {
      order: 0;
    }
    .story-item__video {
      order: 1;
    }
    .story-item__author {
      order: 3;
    }
    .story-item__infos {
      order: 2;
    }
  }

  @include up(1200) {
    padding: 20px 20px 30px 20px;
    gap: 10px 40px;
    grid-template-columns: auto minmax(45%, 870px);
  }
  @include up(1580) {
    padding: 35px 40px 60px 56px;
    gap: 18px 77px;
    grid-template-columns: auto minmax(61%, 870px);
  }

  &:nth-of-type(even) {
    @include up(1200) {
      grid-template-columns: minmax(45%, 870px) auto;
    }
    @include up(1580) {
      grid-template-columns: minmax(61%, 870px) auto;
      .story-item__content {
        order: 1;
      }
      .story-item__video {
        order: 0;
      }
      .story-item__author {
        order: 3;
      }
      .story-item__infos {
        order: 2;
      }
    }
  }

  &__category {
    @include down(1199) {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
  }
  &__category-value {
    font-size: 16px;
    font-weight: 600;
    max-width: 16ch;
  }

  &__author {
    display: flex;
    align-items: center;
    line-height: 1.1;
    gap: 16px;

    @include up(1580) {
      gap: 34px;
    }

    &-image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      flex-shrink: 0;
      img {
        max-width: initial;
        width: 100%;
      }

      @include up(1580) {
        width: 107px;
        height: 107px;
      }
    }

    strong {
      display: block;
      font-family: var(--font-title);
      margin-bottom: 8px;
    }

    span {
      font-size: 16px;
    }
  }

  &__infos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @include down(1199) {
      margin-top: 27px;
      margin-bottom: 30px;
    }
    @include up(1200) {
      justify-content: space-between;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 10px;
    stroke: $black;

    @include up(768) {
      gap: 15px;
    }

    strong {
      display: block;
      font-family: var(--font-title);
    }

    span {
      font-size: 14px;

      @include up(1200) {
        font-size: 16px;
      }
    }
  }

  .btn-secondary {
    @include down(1199) {
      position: absolute;
      bottom: 45px;
      left: 20px;
      width: calc(100% - 40px);
    }
  }
}
