.section-references {
  margin-bottom: 120px;
}

.reference-map {
  border-radius: 10px;
  box-shadow: 0px 10px 30px #eef1f7;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .projectHolder {
    position: absolute;
    right: 30px;
    top: 5%;
    transform: translateX(150%);
    transition: all 0.3s;
    width: min-content;
    width: -webkit-min-content;
    img{
      max-width: 480px;
      width: 480px;
    }
    @include down(1199) {
      img{
        height: 120px;
        width: auto;
      }
      .project-item__content__header{
        height: auto;
      }
      .project-item__funding-legend,
      .progress{
        display: none;
      }
    }
    &.show {
      transform: translateX(0);
    }
  }
  #map {
    height: 500px;
    @include up(1400) {
      height: 800px;
    }
  }

  &__content {
    position: relative;
    top: -100px;
    @include up(1400) {
      position: absolute;
      right: 32px;
      top: 25px;
      bottom: 0;
      margin: auto;
    }
  }
}

.project-count {
  text-align: center;
  margin-bottom: 94px;
}

.references-listing {
  display: grid;
  margin-bottom: 90px;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  @include up(768) {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
  @include up(1200) {
    gap: 90px var(--bs-gutter-x);
  }
}

.reference {
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 10px 30px #eef1f7;
  padding: 20px;

  img {
    margin-bottom: 20px;
  }
}

.section-references .project-item {
  @include down(768) {
    .project-item__thumb {
      aspect-ratio: 360 / 70;
      height: 160px;
      width: 210px;
    }
    .project-item__type,
    .project-item__funding-details,
    .project-item__footer,
    .project-item__closed,
    .project-item__reimbursed {
      display: none;
    }
  }

  @include up(1400) {
    // .project-item__thumb {
    //   aspect-ratio: 360 / 100;
    // }
  }
}
