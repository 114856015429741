body {
  overflow-x: clip;
  background: white;
  // width: 100vw;
  &.fixed{
    overflow-y: hidden;
    padding-right: 14px;
  }
}

main {
  overflow-x: clip;
  margin-top: 40px;
  .home & {
    margin-top: 0;
  }
}

.icon {
  color: var(--color-title);
  fill: #fff;
}

.shadow-mobile {
  @include down(1200) {
    padding: 34px 20px 53px 20px;
    box-shadow: 0px 10px 30px #eef1f7;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 35px;
  }
}

.card {
  border:none;
  box-shadow: 0px 10px 30px #eef1f7;
  border-radius: 10px;
  padding: 38px 10px 44px 10px;
  color: #539f84;
  margin-bottom: 50px;
  @include up(1200) {
    padding: 38px 80px 44px 90px;
  }
}

.panelLine{
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  line-height: 24px;
  font-weight: 700;
  font-family: var(--font-title);
  &--big{
    font-size: 22px;
    margin: 25px 0;
  }
  &--total{
    font-size: 22px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    color: var(--primary);
    &--inverse{
      background:var(--primary) ;
      color: white;
    }
  }
}

.panel {
  color: white;
  background: linear-gradient(90deg, #289db4, #539f84);
  padding: 47px 20px;
  margin-bottom: 17px;

  

  &--icon{
    background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
    border-radius: 50%;
    width: 60px;
    min-width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  &-rounded {
    border-radius: 10px;
    p {
      color: #fff !important;
    }
  }

  @include up(1200) {
    margin-bottom: 50px;
  }

  h1,
  h2,
  h3,
  h4,
  &--inverse h4 {
    color: white;
  }

  &__title {
    margin-bottom: 20px;

    &--icon,
    &--icon-lg-none {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 22px;

      h3,
      h4,
      &--icon-lg-none h3,
      &--icon-lg-none h4 {
        margin-bottom: 0;
        max-width: 20ch;
        margin-left: auto;
        margin-right: auto;
      }

      &--icon-lg-none {
        @include up(1200) {
          flex-direction: column;
          h3,
          h4 {
            max-width: initial;
          }
        }
      }
    }

    img {
      width: 70px;
      height: 70px;

      @include up(1200) {
        width: 102px;
        height: 102px;
      }

      @include up(1580) {
        width: 135px;
        height: 135px;
      }
    }

    @include up(1200) {
      margin-bottom: 40px;
    }
  }

  label,
  label a {
    color: white;
  }

  &.panel--total{
    padding: 40px 40px;
    border-radius: 10px;
    h3{
      margin-bottom: 40px;
    }
    
  }


  &.panel--small {
    background: linear-gradient(141deg, #289db4, #539f84);
    padding: 30px 20px;
    @include up(1200) {
      padding: 58px 48px 45px 36px;
    }
    h4 {
      text-align: center;
    }

    ul li {
      margin-bottom: 1em;
    }
  }

  &.panel--large {
    padding: 40px 0 40px;
    border-radius: 0;

    @include up(768) {
      margin-left: var(--bs-gutter-x);
      margin-right: var(--bs-gutter-x);
    }

    @include up(1200) {
      padding: 120px 0 96px 0;
    }
  }

  &.panel--shadow {
    box-shadow: 0px 10px 30px #eef1f7;
  }

  &.panel--radius--large {
    padding: 40px 90px;
    border-radius: 10px;
    margin-bottom: 30px;
    p{
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    
    &.panel--info{
      padding: 20px 40px;
      p{
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  &.panel--icon-bottom {
    text-align: center;
    padding: 60px 40px 117px 40px;
    position: relative;

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &.panel--inverse {
    background: white;
    color: var(--color-title);

    h4 {
      color: var(--primary);
    }

    .panel__img-wrapper {
      background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
    }
  }

  &__img-wrapper {
    position: absolute;
    bottom: -55px;
    z-index: 1;
    background: white;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    box-shadow: 0px 10px 30px #eef1f7;
    justify-content: center;
    align-items: center;
    display: flex;

    img {
      width: 65px;
    }

    &--large {
      img {
        width: 90px;
      }
    }
  }
}

main .panel a:not([class]) {
  color: white;
  background: initial;

  -webkit-text-fill-color: initial;
  &:hover {
    text-decoration: underline;
  }
}

.bg-icon {
  --bg-icon-rotate-start: 0deg;
  --rotate-start-abs: max(
    var(--bg-icon-rotate-start),
    -1 * var(--bg-icon-rotate-start)
  );
  position: absolute;
  width: 268px;
  height: 268px;
  display: none;
  transform: rotate(var(--bg-icon-rotate-start));

  animation: spin 30s linear infinite;
  pointer-events: none;
  @include up(1440) {
    display: block;
  }
}

@keyframes spin {
  0% {
    transform: rotate(var(--bg-icon-rotate-start));
  }
  100% {
    transform: rotate(calc(360deg - var(--rotate-start-abs)));
  }
}

.warning {
  color: $yellow;
  border: 1px solid currentColor;
  border-radius: 10px;
  margin-bottom: 50px;
  padding: 50px 10px 6px 10px;
  h2,
  h5 {
    color: currentColor;
  }

  .warning-title {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 40px;
    h2 {
      margin-bottom: 0;
    }
  }
}

.logos-wrapper {
  display: flex;
  gap: 26px;
  align-items: center;

  @include down(1199) {
    margin-bottom: 26px;
  }
}

.identity--file{
  border-radius: 10px;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 90%;
  margin-bottom: 30px;
  text-decoration: none;
  transition: all .3s;
  &:hover{
    transform: translateY(-5px);
  }
  .figure{
    width: 55px;
    display: flex;
    height: 100%;
    align-items: center;
    margin: 0;
    figure{
      max-width: 55px;
      position: relative;
      &:after{
        position: absolute;
        content: '!';
        font-weight: 700;
        font-size: 14px;
        color: white;
        background: $yellow;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px white solid;
        right: -10px;
        bottom: -10px;
      }
      img{
        max-width: 55px;
        height: 40px;
        width: auto;
      }
    }
  }
  h6{
    margin: 0 0 5px;
    color: $black;
    font-family: var(--font-title);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
  p{
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
  &.empty{
    p{
      color: $yellow;
    }
  }
  &.pending{
    p{
      color: #616161;
    }
    .figure{
      figure{
        &:after{
          content: '···';
          background-color: #616161;
        }
      }
    }
  }
  &.valid{
    p{
      color: $primary;
    }
    .figure{
      figure{
        &:after{
          font-size: 11px;
          content: '✓';
          background-color: $primary;
        }
      }
    }
  }
  &.invalid{
    p{
      color: #E83F3E;
    }
    .figure{
      figure{
        &:after{
          background-color: #E83F3E;
        }
      }
    }
  }
}