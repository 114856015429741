.hero-content{
  margin-top: 5rem;
}

.page-content{

}

.usercare{
  h3{
    margin: 35px 0;
  }
  .sidebar-steps{
    padding: 40px;
    nav a{
      color: $black;
      display: flex;
      text-decoration: none;
      display: flex;
      align-items: top;
      gap: 20px;
      text-decoration: none;
      position: relative;
      &:hover{
        color: $primary;
      }
  
      &:before{
        content: "";
        display: block;
        border: 2px solid #539f84;
        border-radius: 50%;
        min-width: 16px;
        height: 16px;
        margin-top: 5px;
      }
    }
  }
 
}

.complain{
  .identity--file{
    cursor: pointer;
    align-items: flex-start;
    width: auto;
    figure{
      &::after{
        content:none;
      }
    }
    p{
      color: $black;
    }
  }
}