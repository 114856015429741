/* Colors
  ========================================================================== */
$primary: #539f84;
$black: #2b3447;
$white: #ffffff;
$yellow: #eeb72d;
$red: #e54724;

:root {
  --primary: #{$primary};
  --color-title: #{$black};
}
