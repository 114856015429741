.dropdown-toggle {
  white-space: nowrap;

  &::after {
    display: inline-block;
    margin-left: 0.75rem;
    vertical-align: 0.155em;
    content: "";
    border: 0;
    width: 16px;
    height: 8px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 10'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 8 8 8-8'/%3E%3C/svg%3E%0A");
  }
}

.dropdown-menu {
  --bs-dropdown-spacer: 0;
  --bs-dropdown-min-width: initial;
  --bs-dropdown-link-hover-color: #{$primary};
  --bs-dropdown-link-hover-bg: transparent;
  --bs-dropdown-link-active-color: #{$primary};
  --bs-dropdown-link-active-bg: transparent;
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
  list-style: none;
  background: transparent;
  border: 0;
  padding: 10px 20px;
  li + li {
    margin-top: 5px;
  }
}

.dropdown-item {
  font-weight: 700;
  line-height: 1.2;
  background-clip: text;
  text-decoration: none;
  position: relative;
  color: white;
  display: inline-block;
  width: auto;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    color: white;
    &::after {
      opacity: 1;
    }
  }
}
