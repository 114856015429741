.investments-in-progress {
  margin-bottom: 123px;
  position: relative;
  .bg-icon {
    right: -20px;
    top: 480px;
    width: 550px;
    height: 550px;
    --bg-icon-rotate: -25deg;
    z-index: -1;
  }
}

.modal {
  @include down(576) {
    .investment {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.investment {
  background: #ffffff;

  box-shadow: 0px 10px 40px rgba(209, 220, 242, 0.8);
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 30px;
  --color-infos: #eeb72d;

  @include down(576) {
    margin-left: -20px;
    margin-right: -20px;
  }

  @include up(1400) {
    padding: 35px 35px 30px 35px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 300px auto auto 250px;
    grid-template-rows: auto 1fr auto;
    gap: 18px 32.5px;
    grid-template-areas:
      "image header header-right header-right"
      "image content content content"
      "infos footer footer footer";

    .investment-title {
      grid-area: header;
    }
    .investment-reference {
      grid-area: reference;
    }
    .investment-header {
      grid-area: header-right;
    }
    .investment-image {
      height: 100%;
      grid-area: image;
    }
    .investment-infos {
      grid-area: infos;
    }
    .investment-content {
      grid-area: content;
    }
    .investment-footer {
      grid-area: footer;
    }
  }

  @include up(1580) {
    padding: 50px 50px 30px 50px;
    grid-template-columns: 300px auto auto 270px;
  }

  .investment-table.investment-table--data .investment-table-content {
    @include up(1400) {
      align-items: center;
    }
  }

  .investment-table-cell {
    @include up(1400) {
      span {
        max-width: 25ch; 
      }
    }
  }

  &.investment--paid {
    --color-infos: var(--primary);

    .investment-table.investment-table--data .investment-table-header,
    .investment-table.investment-table--data .investment-table-content {
      @include down(1399) {
        gap: 15px;
      }
    }
  }
  &.investment--closed {
    --color-infos: var(--primary);
    background: #f6f6f6;
    .investment-labels {
      opacity: 0.5;
    }

    .investment-table.investment-table--highlight .investment-table-header {
      @include up(1400) {
        background: #ededee;
        .investment-table-cell span strong {
          color: var(--color-title);
        }
      }
    }
  }
  &.investment--sold {
    @include up(1200) {
      .investment-labels {
        gap: 0;
        .label:first-of-type {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .label + .label {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .investment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    @include up(1400) {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 250px;
      grid-template-rows: 1fr;
      gap: 25px;
      margin-bottom: 0;
      grid-template-areas: "labels reference";
    }

    @include up(1580) {
      grid-template-columns: 1fr 270px;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    h4 {
      @include up(1400) {
        margin-bottom: 0;
      }
    }
  }

  &-reference {
    text-align: right;
  }

  &-labels {
    grid-area: header;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    grid-area: labels;
    gap: 10px;
    flex-direction: column;
    @include up(1200) {
      align-items: center;
      flex-direction: row;
    }
  }

  &-image__wrap {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 30px;
    height: 100%;

    @include up(1400) {
      margin-bottom: 0;
    }

    img {
      object-fit: cover;
      aspect-ratio: 2.57 / 1;
      width: 100%;
      height: 100%;
      @include up(1400) {
        aspect-ratio: 1 / 1;
      }
    }
    &::before {
      /*background: radial-gradient(
          91.38% 91.38% at 95.07% 13.57%,
          #ffffff 0%,
          #289db4 100%,
          #289db4 100%
        )*/
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      mix-blend-mode: darken;
      opacity: 0.3;
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
    }
    &::after {
      /*background: radial-gradient(
          82.36% 82.36% at 0.93% 96.09%,
          #399f7d 50%,
          rgba(40, 157, 180, 0.44) 100%
        )*/
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      mix-blend-mode: normal;
      opacity: 0.3;
      content: "";
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  }

  &__category {
    display: flex;
    align-items: center;
    gap: 16px;
    background: var(--primary);
    position: absolute;
    right: 0;
    z-index: 2;
    padding: 12px 12px 9px 12px;
    border-radius: 10px 0 0 10px;

    top: 11px;
    @include up(1200) {
      top: initial;
      bottom: 33px;
    }
    &-value {
      font-size: 16px;
      font-weight: 600;
      max-width: 14ch;
      display: block;
      color: white;
    }
    svg {
      stroke: white;
    }
  }

  &__type {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 700;
    font-family: var(--font-title);
    text-transform: uppercase;

    color: white;
    border-radius: 10px;
    display: inline-block;

    @include up(1200) {
      font-size: 16px;
    }
  }

  &-infos {
    line-height: 1;
    font-weight: 700;
    color: var(--color-infos);

    @include up(1400) {
      margin-top: -10px;
    }
    &__price {
      font-family: var(--font-title);
    }
    span {
      display: block;
    }

    small {
      font-size: 14px;
      line-height: 1;
      font-weight: 600;
    }

    .view-infos {
      text-decoration: none;
      color: var(--color-infos);
      font-size: 16px;
      margin-top: 10px;
      display: inline-block;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .buttons-wrapper {
    margin-top: 30px;
    @include up(1400) {
      justify-content: flex-end;
    }
  }
}

.investment-table-wrapper {
  margin-bottom: 20px;
  @include up(1400) {
    margin-bottom: 0;
    display: flex;
    gap: 25px;
  }
}

.table-invest {
  tbody {
    td {
      white-space: nowrap;
    }
  }
}

.investment-table {
  background: #ffffff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 10px 30px #eef1f7;

  overflow: hidden;

  display: flex;
  font-size: 14px;

  @include up(576px) {
    font-size: 16px;
  }

  @include up(1400) {
    display: block;
    border-radius: 10px;
  }

  &.investment-table--data {
    width: 100%;

    @include down(1399) {
      justify-content: space-between;
    }
    .investment-table-header {
      min-width: 54%;
      @include down(1399) {
        display: grid;
      }
      @include up(1400) {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        &--coinvest{
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }
    .investment-table-content {
      @include down(1399) {
        display: grid;
      }
      @include up(1400) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: flex-end;
        &--coinvest{
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
    }
  }

  .investment-table-content--coinvest,
  .investment-table-header--coinvest {
    @include up(1400) {
      &.no-datas{
        grid-template-columns: 1fr 1fr ;
      }
    }
    .investment-table-cell{
      span{
        max-width: none;
      }
    }
  }

  &.investment-table--highlight {
    min-width: 250px;
    background: #539f84;
    color: white;
    border-radius: 0 0 10px 10px;

    @include down(1399) {
      align-items: center;
      justify-content: space-between;
    }

    @include up(1400) {
      color: var(--color-title);
      border-radius: 10px;
      background: white;
    }
    @include up(1580) {
      min-width: 270px;
    }

    .investment-table-header {
      background: #539f84;
      .investment-table-cell {
        span {
          max-width: 20ch;
          strong {
            color: white;
          }
        }
      }
    }

    .investment-table-content {
      @include up(1400) {
        padding: 43px 50px 20px 50px;
      }
    }

    .investment-table-cell strong {
      @include up(1400) {
        color: var(--primary);
      }
    }
  }
}
.investment-table-header {
  background: #ededee;
  align-items: center;
  padding: 12px 20px;

  @include up(1200) {
    padding: 15px 25px;
  }

  @include up(1400) {
    display: flex;
    justify-content: center;
    min-height: 90px;
  }

  @include up(1580) {
    padding: 15px 50px;
  }
}
.investment-table-content {
  padding: 12px 20px;
  @include down(1399) {
    .investment-table-cell {
      text-align: right;
    }
  }
  @include up(1200) {
    padding: 15px 25px 25px 25px;
  }
  @include up(1400) {
    min-height: 130px;
  }

  @include up(1580) {
    padding: 15px 50px 25px 50px;
  }
}

.investment-table-header,
.investment-table-cell strong {
  font-family: var(--font-title);
}

.investment-table-cell {
  position: relative;
  span {
    display: block;
  }

  .infos-label {
    cursor: pointer;
    background: #eeb72d;
    opacity: 0.5;
    width: 16px;
    height: 16px;
    display: flex;
    position: absolute;
    top: -5px;
    right: -16px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: 250ms opacity;

    font-weight: 700;
    font-size: 12px;

    color: #ffffff;
    font-family: var(--font-title);

    &:hover {
      opacity: 1;
    }

    @include up(480) {
      width: 25px;
      height: 25px;
      font-size: 16px;
      right: 0;
    }
  }

  @include up(1400) {
    text-align: center;

    span {
      margin: 0 auto;
      text-align: center;
      max-width: 14ch;
    }
  }
}

.investment{
  @include down(1199) {
    .investment-table-header .investment-table-cell{
      display: flex;
      //flex-wrap: wrap;
      //align-items: center;
      .infos-label{
        position: relative;
        margin-top: 3px;
        top: inherit;
        right: inherit;
        margin-right: 5px;
        min-width: 16px;
      }    
    }
  }
} 
.investment-table--highlight .investment-table-cell .infos-label {
  right: 0;
  @include down(1199) {
    right: 0;
  }
}
.investments-closed {
  margin-bottom: 120px;
}

.modal-investment {
  @include up(1200) {
    --bs-modal-width: 1160px;
  }
  @include up(1440) {
    --bs-modal-width: 1400px;
  }
  @include up(1580) {
    --bs-modal-width: 1520px;
  }
  .investment {
    background: transparent;
    box-shadow: none;

    @include up(1400) {
      grid-template-columns: 300px auto auto 270px;
      grid-template-rows: auto auto auto auto auto;

      grid-template-areas:
        "image header header-right header-right"
        "image content content content"
        "infos . . ."
        "table-legend table table table"
        ". footer footer footer";
    }

    .table-legend {
      grid-area: table-legend;
    }

    .table-wrapper {
      margin-bottom: 0;
      grid-area: table;
    }
  }

  .modal-content {
    padding: 0;
  }

  .table-legend {
    margin-top: 30px;
    h4 {
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}
