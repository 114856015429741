// .btn {
//   --bs-btn-padding-x: 0.75rem;
//   --bs-btn-padding-y: 0.375rem;
//   --bs-btn-font-family: ;
//   --bs-btn-font-size: 1rem;
//   --bs-btn-font-weight: 400;
//   --bs-btn-line-height: 1.5;
//   --bs-btn-color: #212529;
//   --bs-btn-bg: transparent;
//   --bs-btn-border-width: var(--bs-border-width);
//   --bs-btn-border-color: transparent;
//   --bs-btn-border-radius: 0.375rem;
//   --bs-btn-hover-border-color: transparent;
//   --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
//   --bs-btn-disabled-opacity: 0.65;
//   --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
//   display: inline-block;
//   padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
//   font-family: var(--bs-btn-font-family);
//   font-size: var(--bs-btn-font-size);
//   font-weight: var(--bs-btn-font-weight);
//   line-height: var(--bs-btn-line-height);
//   color: var(--bs-btn-color);
//   text-align: center;
//   text-decoration: none;
//   vertical-align: middle;
//   cursor: pointer;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   user-select: none;
//   border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
//   border-radius: var(--bs-btn-border-radius);
//   background-color: var(--bs-btn-bg);
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }

%button {
  font-family: var(--font-title);
  line-height: 1.25;
  text-align: center;
  letter-spacing: 1.77778px;
  text-transform: uppercase;

  border-radius: 30px;
  text-decoration: none;
  padding: 15px 25px;
  cursor: pointer;
  transition: 250ms all;
  white-space: nowrap;

  font-size: 14px;
  display: block;
  width: 100%;

  @include up(1200) {
    width: fit-content;
    font-size: 16px;
    display: inline-block;
    padding: 19px 25px;
  }
  @include up(1600) {
    padding: 19px 50px;
  }

  span {
    white-space: nowrap;
    font-weight: 700;
    background: linear-gradient(90deg, #6db29b 0%, $yellow 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    transition: 250ms all;
  }
  &.btn-block {
    width: 100%;
    display: block;
  }
}

.btn-main {
  @extend %button;
  border: 1px solid #f3f4f6;

  background: linear-gradient(
    90deg,
    rgba(109, 178, 155, 1) 0%,
    rgba(238, 183, 45, 1) 50%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );

  background-size: 200% auto;
  background-position: 100% 0;
  background-origin: border-box;

  span {
    background: linear-gradient(90deg, #6db29b 0%, $yellow 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    border: 1px solid transparent;
    background-position: 0 0;
    span {
      background: #fff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
}

.btn-main--shadow {
  box-shadow: 0px 10px 30px 0px #eef1f6;
  &:hover {
    box-shadow: 0px 10px 30px 0px transparent;
  }
}

.btn-secondary {
  @extend %button;

  background: linear-gradient(90deg, #539f84 0%, #289db4 100%);

  border: solid 2px transparent;
  padding: 14px 25px;
  background-origin: border-box;
  box-shadow: 0px 10px 30px #eef1f7;

  span {
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.closed{
    display: block;
    padding: 20px 50px;
    pointer-events: none;
    border-color: none;
    background: white;
    box-shadow: none;
    &:hover{
      pointer-events: none;
    }
    span{
      background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      word-break: break-word;
      white-space: break-spaces;
    }
  }

  @include up(1200) {
    font-size: 16px;
    display: inline-block;
    padding: 19px 25px;
  }
  @include up(1600) {
    padding: 19px 50px;
  }

  @include up(1600) {
    padding: 18.5px 50px;
  }

  &:hover {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(90deg, #539f84 0%, #289db4 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
    border: solid 2px transparent;
    span {
      background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  &.teasing{
    cursor: default;
    box-shadow: none;
    background: white;
    color:$yellow;
    font-weight: 700;
  }

}

.btn-login {
  span {
    display: none;
  }

  @include down(1199) {
    background: transparent;
    padding: 0;
    border: 0;
    &:hover {
      border: 0;
    }
  }

  @include up(1200) {
    svg {
      display: none;
    }
    span {
      display: inline-block;
    }
  }
}

.btn-project {
  background: white;
  color: #539f84;
  display: block;
  border: 1px solid #539f84;
  border-radius: 24.5px;
  text-align: center;
  font-family: var(--font-title);
  font-weight: 700;
  padding: 7px;
  text-decoration: none;
  transition: all 0.25s;
  &:hover {
    background: linear-gradient(90deg, #539f84 0%, #289db4 100%);
    color: #fff;
  }
}

.btn-collapse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include up(1200) {
    display: inline-block;
    width: initial;
  }
  color: var(--color-title);
  text-decoration: none;
  font-family: var(--font-filters);
  &::after {
    display: inline-block;
    margin-left: 40px;
    width: 16px;
    height: 9px;
    content: "";
    transition: 250ms transform;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 9'%3E%3Cpath fill='%232B3447' fill-rule='evenodd' d='m14.077.774 1.179 1.12L8 8.785.744 1.893 1.923.773 8 6.548 14.077.774Z' clip-rule='evenodd'/%3E%3C/svg%3E");
  }

  &[aria-expanded="true"] {
    &::after {
      transform: rotate(-180deg);
    }
  }
}

.buttons-wrapper {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-top: 20px;
  @include up(1200) {
    flex-direction: row;
  }
}

.btn--small {
  padding: 14px 28px;
  @include up(1200) {
    padding: 14px 28px;
  }
  @include up(1600) {
    padding: 14px 28px;
  }
}

.btn-lock{
  width: fit-content;
  font-size: 12px;
  line-height: 11px;
  text-transform: uppercase;
  text-decoration: none;
  color: #2B3447;
  border: 1px solid #2B3447;
  border-radius: 30px;
  padding: 7px 8px 6px;
  margin-bottom: 5px;
  .closed &{
    color: white;
    border-color: white;
  }
  svg{
    margin-top: -2px;
  }
}
span.btn-lock{
  color: #fff;
  border: 1px solid #fff;
}
a.btn-lock:hover{
  background-color: #2B3447;
  color: #fff;
  .closed &,
  .project-infos &{
    color: #2B3447 !important;
    background-color: white;
    border-color: white;
  }
}