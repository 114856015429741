$themeColor: white !default;

@at-root {
  :root {
    --swiper-theme-color: #{$themeColor};
    --swiper-navigation-size: 21px;
    --swiper-pagination-bullet-size: 10px;
    --swiper-pagination-bullet-width: 10px;
    --swiper-pagination-bullet-height: 10px;

    --swiper-pagination-bullet-horizontal-gap: 10px;
    /*
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-vertical-gap: 6px;
  */
  }
}

@media (min-width: 1200px) {
  :root {
    --swiper-pagination-bullet-size: 16px;
    --swiper-pagination-bullet-width: 16px;
    --swiper-pagination-bullet-height: 16px;
    --swiper-pagination-bullet-horizontal-gap: 13px;
  }
}

.swiper-container {
  position: relative;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-pointer-events {
  touch-action: pan-y;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}
/* Auto Height */
.swiper-autoheight {
  &,
  .swiper-slide {
    height: auto;
  }

  .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
}
.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}

.swiper-centered {
  > .swiper-wrapper::before {
    content: "";
    flex-shrink: 0;
    order: 9999;
  }
  &.swiper-horizontal {
    > .swiper-wrapper > .swiper-slide:first-child {
      margin-inline-start: var(--swiper-centered-offset-before);
    }
    > .swiper-wrapper::before {
      height: 100%;
      width: var(--swiper-centered-offset-after);
    }
  }

  > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: center center;
    scroll-snap-stop: always;
  }
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  &.swiper-pagination-hidden {
    opacity: 0;
  }
  .swiper-pagination-disabled > &,
  &.swiper-pagination-disabled {
    display: none !important;
  }
}
/* Common Styles */
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {

  left: 0;
  width: 100%;
  bottom: 30px;
  @include up(1440) {
    bottom: 82px;
  }
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: 50%;
  background: transparent;
  border: 1px solid white;
  @include up(1200) {
    border: 2px solid white;
  }
  transition: 250ms background-color;
  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }
  .swiper-pagination-clickable & {
    cursor: pointer;
  }

  &:only-child {
    display: none !important;
  }
}
.swiper-pagination-bullet-active {
  background: white;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-horizontal.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  }
}
.swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 21px;
  height: 18px;
  margin-top: calc(0px - (18px / 2));
  z-index: 10;
  cursor: pointer;
  background-repeat: no-repeat;
  &.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  &.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-navigation-disabled & {
    display: none !important;
  }
}

.swiper-navigation-disabled + .swiper-button-prev,
.swiper-navigation-disabled + .swiper-button-prev + .swiper-button-next {
  display: none !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: -40px;
  right: auto;
  transition: background-image 250ms;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 21'%3E%3Cpath stroke='%232B3447' stroke-linecap='round' d='M1.962 8.772c-1.323.772-1.323 2.684 0 3.456l12.03 7.018c1.334.777 3.008-.184 3.008-1.728V3.482c0-1.544-1.674-2.505-3.008-1.727L1.962 8.772Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23539f84' viewBox='0 0 18 21'%3E%3Cpath stroke='%23539f84' stroke-linecap='round' d='M1.962 8.772c-1.323.772-1.323 2.684 0 3.456l12.03 7.018c1.334.777 3.008-.184 3.008-1.728V3.482c0-1.544-1.674-2.505-3.008-1.727L1.962 8.772Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
  }
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: -40px;
  left: auto;
  transition: background-image 250ms;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 21'%3E%3Cpath stroke='%232B3447' stroke-linecap='round' d='M16.038 8.772c1.323.772 1.323 2.684 0 3.456l-12.03 7.018C2.674 20.023 1 19.061 1 17.517V3.482C1 1.938 2.674.977 4.008 1.755l12.03 7.017Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23539f84' viewBox='0 0 18 21'%3E%3Cpath stroke='%23539f84' stroke-linecap='round' d='M16.038 8.772c1.323.772 1.323 2.684 0 3.456l-12.03 7.018C2.674 20.023 1 19.061 1 17.517V3.482C1 1.938 2.674.977 4.008 1.755l12.03 7.017Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
  }
}

.swiper-button-lock {
  display: none;
}
