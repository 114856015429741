.calendar {
  position: relative;
  .bg-icon {
    right: -20px;
    top: 50%;
    width: 550px;
    height: 550px;
    --bg-icon-rotate: -25deg;
    z-index: -1;
  }
}

.calendar-grid {
  margin-top: 90px;
}
